import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { ArrowForward } from '@mui/icons-material';
import { Content, ContentBetween, ContentColumn } from './Content';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FocusDialogueFull({ profile }) {
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} size="large" variant="text" className="capitalize mx-3 text-gray-800"><ArrowForward /></IconButton>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} className='bg-dark-green'>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" onClick={handleClose}>
              {profile.title}
            </Typography>
           
          </Toolbar>
        </AppBar>
       <ContentColumn className={"justify-content-center align-items-center h-full"}>
        <Content className={"w-full h-15rem bg-gray-500 flex justify-content-center align-items-center bg-cover bg-no-repeat bg-center"} style={{background: `url(${profile?.image})`}}>
           {profile.icon}
        </Content>
        <ContentColumn className={"col-md-10 mx-auto pt-4 px-5 md:px-0 mb-7 overflow-y-scroll"} style={{ maxHeight: '60vh' }}>
           <Typography variant="h6" className='mb-3'>{profile.title}</Typography>
           { profile?.intro.map((intro, index) => <Typography key={index} variant='p' className='mb-3'>
              {intro}
           </Typography> )}

        </ContentColumn>

        <ContentColumn className={"bg-dark-green h-5rem w-full mt-auto"}>
                   <Content className={"hidden md:flex justify-content-between ml-auto col-10 bg-nelo-green border-round-xl border-noround-right px-3 py-4"} style={{ marginTop: -35 }}>
                        <Typography variant="h5" className='font-bold text-gray-900'>Ready to get started?</Typography>
                        <Button href='/contact' className='text-white'>Contact us <ArrowForward  className='ml-3'/></Button>
                   </Content>
                   <Content className={"flex md:hidden flex-column ml-auto col-10 bg-nelo-green border-round-xl border-noround-right px-3"} style={{ marginTop: -45 }}>
                        <Typography variant="h5" className='font-bold text-gray-900'>Ready to get started?</Typography>
                        <Button href='/contact' className='ml-auto text-white'>Contact us <ArrowForward  className='ml-3'/></Button>
                   </Content>
        </ContentColumn>

       </ContentColumn>
      </Dialog>
    </React.Fragment>
  );
}