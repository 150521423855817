import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Content } from '../../../components/Content';

const uData = [400, 300, 200, 278, 189, 239, 349];
const pData = [240, 139, 980, 390, 480, 380, 430];
const xLabels = [
  'Gauteng',
  'Limpopo',
  'Western Cape',
  'Kwa Zulu',
  'Mpumalanga',
  'North West',
  'Eastern Cape',
];

export default function DeliveriesChart() {
  return (
    <Content className={"shadow-2 border-round-md p-2"}>

    <BarChart
      height={300}
      series={[
        { data: pData, label: 'HP', id: 'pvId' },
        { data: uData, label: 'Dell', id: 'uvId' },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
    />
    </Content>

  );
}
