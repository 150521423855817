import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Content, { ContentColumn } from './Content';
import { AddBusiness, Apartment, Create, CreditCard, NotificationsOutlined } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { useApi } from '../useApi';
import { useAuthContext } from '../AuthContext';
import Loading from './Loading';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from './ConfirmDialoge';
import CreateDropdownItem from '../modules/clientzone/CreateDropdownItem';


const paperProps = {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  }


  const loginAlert  = {
    title: "Logout",
    message: "You are about to logout from Clientzone, are you sure?",
    action: "Logout"
 }

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [creatingObject, setCreatingObject] = React.useState(null);

  const api = useApi();
  const { state, dispatch, setToast } = useAuthContext();
  const { currentUser } = state;

  const open = Boolean(anchorEl);

  const { logout } = useAuthContext();
  const navigate = useNavigate();

  const [confirmLogin, setConfirmLogin] = React.useState(null)

  const doLogout = () => {
       setConfirmLogin(null)
       logout().then(() => {
          navigate("/clientzone")
       }).catch(console.log).finally(handleClose)
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setLoading(true)
      api.get(`/users/current_user/`).then((response) => {
        //console.log(response.data)
        dispatch({ type: 'set', item: 'currentUser', value: response.data })
      })
      .catch(console.log)
      .finally(() => setLoading(false))
  },[api])

  return (
    <React.Fragment>
      { loading && <Loading /> }
      { confirmLogin && <ConfirmDialog alert={confirmLogin} onAgree={doLogout} onDisAgree={setConfirmLogin} /> }
      { creatingObject && <CreateDropdownItem onModelClose={setCreatingObject} modelName={creatingObject}  /> }

      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

         <DropdownMenu tooltip={`${currentUser?.notifications?.length} Notifications`} menuItems={<NotificationsPreview items={currentUser?.notifications} />}> 
            <Badge badgeContent={currentUser?.notifications?.length} color="primary" className="mr-1">
                <NotificationsOutlined className="text-silver" />
            </Badge>   
         </DropdownMenu>

         <DropdownMenu tooltip="Account settings" menuItems={<AccountSettings currentUser={currentUser} handleLogout={() => setConfirmLogin(loginAlert)} setCreatingObject={setCreatingObject}/>}>
            <Avatar sx={{ width: 34, height: 34 }}>{currentUser?.first_name.slice(0,1).toUpperCase()}</Avatar>
            <Content className={'hidden md:inline ml-3'}>
                <ContentColumn>
                    <Typography className="line-height-1 text-white text-left">{currentUser?.full_name}</Typography>
                    <Typography className="text-sm text-silver line-height-1 text-left">{currentUser?.email}</Typography>
                </ContentColumn>
            </Content>
         </DropdownMenu>
      </Box>
    </React.Fragment>
  );
}



const AccountSettings = ({ handleClose =  f => f, handleLogout = f => f, setCreatingObject = f => f, currentUser}) => {
   const navigate = useNavigate();
    
    return (
       <React.Fragment>

         <MenuItem onClick={() => navigate('/clientzone/account/profile')}  style={{ width: 200 }}>
         <Avatar sx={{ width: 34, height: 34 }} src={currentUser?.gravatar_url} >{currentUser?.first_name.slice(0,1).toUpperCase()}</Avatar> {currentUser?.full_name}
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <Avatar sx={{ width: 34, height: 34 }}><CreditCard /></Avatar> Billing
        </MenuItem>
        <Divider />
        { currentUser.is_superuser && <MenuItem onClick={() => setCreatingObject('users')}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another user
        </MenuItem> }
        { currentUser.is_superuser && <MenuItem onClick={() => setCreatingObject('customers')}>
          <ListItemIcon>
            <AddBusiness fontSize="small" />
          </ListItemIcon>
          Add customer
        </MenuItem> }
        <MenuItem onClick={() => navigate('/clientzone/settings')}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
       </React.Fragment>
    )
}

const NotificationsPreview = ({ handleClose = f => f, items }) => {
    return (
        <React.Fragment>
                <MenuItem onClick={handleClose}>
                   0 notifications found
                </MenuItem>
        </React.Fragment>
        
    )
}


const DropdownMenu = ({ children, tooltip, menuItems }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (

        <React.Fragment>
         <Tooltip title={tooltip}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {children}
          </IconButton>
        </Tooltip>
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{ paper : paperProps } }
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems}
      </Menu>

        </React.Fragment>
    )
    
}