import { Button, Typography } from "@mui/material";
import { ContentColumn } from "../../components/Content";
import { useNavigate } from "react-router-dom";

export default function PageNotFound()
{
    const navigate = useNavigate()
    return <ContentColumn className={"flex justify-content-center py-7 align-items-center"} style={{ width: '100vw', marginTop: 200 }}>
        <Typography className="text-5xl font-bold text-nelo-green">404</Typography>
        <Typography className="text-3xl font-bold">Page not found</Typography>
        <Typography className="text-xl font-light">The requested resource could not found on this server.</Typography>
        <Button onClick={() => navigate(-1)} className="bg-nelo-green w-8rem text-white my-3">Go Back</Button>
    </ContentColumn>
}

