const textControl = { size: 4, type: 'text' }
const emailControl = { size: 4, type: 'email' }
const textAreaControl = { size: 4, type: 'textarea', rows: 4 }
const phoneControl = { size: 4, type: 'phone', row: 4 }
const numberControl = { size: 4, type: 'number', row: 4 }
const currencyControl = { size: 4, type: 'currency', row: 4, step: 0.01, suffix: 'R' }


//Model for creating the cutsomer
export const createCustomerForm = [
    { name: "name", ...textControl, label: 'Name', focus: true, required: true, min: 5 },
    { name: "email", ...emailControl, label: 'Email', required: true },
    { name: "cell_number", ...phoneControl, label: 'Cellphone number', required: true, min: 10 },
    { name: "business_number", ...phoneControl, label: 'Business number', required: true, min: 10 },
    { name: "credit_limit", ...currencyControl, label: 'Credit limit', required: true },
    { name: "address", ...textAreaControl, label: 'Address', required: true, min: 20 },
]

const serviceCreateForm =  [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: 'Unique ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      type: 'text'
    },
    {
    id: 'recurring',
    numeric: true,
    disablePadding: false,
    label: 'Recurring',
    type: 'select',
    choices: ["Hourly", "Weekly", "Monthly", "Quarterly"]
    },
  ];


const customerCreateForm =  [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: 'Unique ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      type: 'text'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      type: 'email'
    },
    {
      id: 'business_number',
      numeric: false,
      disablePadding: true,
      label: 'Business number',
      type: 'phone'
    },
{
    id: 'balance',
    numeric: true,
    disablePadding: false,
    label: 'Balance',
  },
  ];

const userCreateForm = [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: 'Unique ID',
    },
    {
      id: 'full_name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      type: 'text'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      type: 'email'
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: true,
      label: 'Role',
      type: 'select',
      choices: [ "Admin", "Finance", "Technical User", "Agent"]
    },
    {
        id: 'last_login',
        numeric: false,
        disablePadding: true,
        label: 'Last login',
      },
  ];

const incidentCreateForm = [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: 'Unique ID',
    },
    {
      id: 'short_description',
      numeric: false,
      disablePadding: true,
      label: 'Short description',
      type: 'text'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      type: 'select',
      choices: ['New','In progress','Resolved','Closed','Cancelled','Rejected']
    },
    {
      id: 'severity',
      numeric: false,
      disablePadding: true,
      label: 'Severity',
      type: 'select',
      choices: ['Low','Normal','Medium','High','Critical']
    },
    {
      id: 'customer__name',
      numeric: false,
      disablePadding: true,
      label: 'Customer',
      type: 'select',
      choices: [
       
      ]
    },
    {
        id: 'description',
        numeric: false,
        hidden: true,
        disablePadding: true,
        label: 'Description',
        type: 'textarea',
        rows: 4
      },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: true,
        label: 'Created at',
      },
  ];

//Object that returns all available models for create forms
export const getCreateModel = (modelName) => {
    let modelNames = {
    'customers': createCustomerForm,
    'services': serviceCreateForm,
    'users': userCreateForm,
    'incidents': incidentCreateForm
    } 
    
    return modelNames[modelName] || []
}