import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Content, ContentBetween, ContentColumn, ContentEnd, ContentRow, ContentStart, RowCol } from "../../components/Content";
import { EmailOutlined, Map, Phone } from "@mui/icons-material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';
import axios from "axios";


const initialValues = {
    name: "",
    email: "",
    phone: "",
    reason: "",
    comments: ""
}
export default function Contact()


{

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState(null)
  const [showPass, setShowPass] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Your name is required").min(3, "Username is too short."),
    email: Yup.string().required("email is required").email('Email address invalid').min(8, "This email seems too short."),
    phone: Yup.string().required("Where do we call you?").min(10, "Phone number is too short."),
    reason: Yup.string().required("Reason for contact required").min(5, "Reason is too short."),
  })

  function handleContact(contact) {
    setShowPass(null)
    setError(null)
    setLoading(true)
    axios.post("/api/v1/webcontacts/", contact).then((response) => {
        //console.log(response)
        setShowPass("Form submitted successfuly, we will be in contact shortly.")
    }).catch((e) => {
        setError(e?.response?.statusText)
    })
    .finally(() => setLoading(false))
  }

const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (data, {resetForm}) => {
            //console.log(data)
            handleContact(data)
            resetForm({ values: '' })
        }
})

    return <ContentColumn className="bg-white py-0" style={{ width: '100vw'}}>

    <ContentColumn className={"py-0"}>
        <ContentColumn className={"col-md-8 mx-auto"}>
            <ContentColumn className={"flex col-md-10 align-items-start justify-content-center h-15rem pt-5"}>
            <ContentStart className={"align-items-center"}><Typography className="text-xl md:text-4xl line-height-1">Embrace innovation</Typography></ContentStart>
            <Typography className="text-lg md:text-2xl line-height-1 text-gray-500">Across the boundaries.</Typography>
            </ContentColumn>
        </ContentColumn>
    </ContentColumn>

<ContentColumn className={"py-3 bg-nelo-light m-0"}>
<ContentColumn className={"col-md-8 mx-auto py-3 px-3"}>
  <Typography className="text-lg mb-5">Contact us</Typography>
  

  <ContentRow className={"m-0 row row-cols-1 row-cols-md-2"}>
        <Content className={"col-md-6 px-0"}>
                <ContentColumn className={"align-items-start"}>
                    <Typography className="col-md-8 text-xl line-height-2 text-gray-800">Nelotec (Pty) Ltd<br/>We are now a fully remote office</Typography>
                    <ContentStart className={"mt-5 align-items-center"}>
                         <Phone />
                        <Typography className="ml-3 text-boxy line-height-1 text-gray-800"> +27 12 110 4777</Typography>
                    </ContentStart>
                    <ContentStart className={"mt-1 align-items-center"}>
                         <EmailOutlined />
                        <Typography className="ml-3 text-boxy line-height-1 text-gray-800"> contact@nelotec.co.za</Typography>
                    </ContentStart>
                    <ContentStart className={"mt-1 align-items-center"}>
                         <Map />
                        <Typography className="ml-3 text-boxy line-height-1 text-gray-800">1065 Spoonwood str, Technopark, Centurion, 0157</Typography>
                    </ContentStart>
                </ContentColumn>
        </Content>
        <Content className={"px-0 col-md-5 py-3"}>
            <form onSubmit={formik.handleSubmit} noValidate onChange={() => setShowPass(null)}>
            <Typography className="font-bold text-lg">Get in touch</Typography>
            { showPass && <Typography className="text-lg text-white">{showPass}</Typography> }

                <TextField value={formik.values.name} required fullWidth id="standard-basic" label="Contact name" variant="standard" onChange={formik.handleChange} name="name" error={formik.errors.name&& formik.touched.name} />
                {formik.errors.name && formik.touched.name? (<small className="text-xs py-0 w-full text-red-600">{formik.errors.name}</small>) : null}
                <TextField value={formik.values.email} required fullWidth id="standard-basic" label="Email address" variant="standard" onChange={formik.handleChange} name="email" error={formik.errors.email&& formik.touched.email}/>
                {formik.errors.email && formik.touched.email ? (<small className="text-xs py-0 w-full text-red-600">{formik.errors.email}</small>) : null}
                <TextField value={formik.values.phone} required fullWidth id="standard-basic" label="Contact number" variant="standard" onChange={formik.handleChange} name="phone" error={formik.errors.phone&& formik.touched.phone}/>
                {formik.errors.phone && formik.touched.phone? (<small className="text-xs py-0 w-full text-red-600">{formik.errors.phone}</small>) : null}
                <TextField value={formik.values.reason} required fullWidth id="standard-basic" label="Reason for contact" variant="standard" onChange={formik.handleChange} name="reason" error={formik.errors.reason&& formik.touched.reason}/>
                {formik.errors.reason && formik.touched.reason ? (<small className="text-xs py-0 w-full text-red-600">{formik.errors.reason}</small>) : null}
             <TextField value={formik.values.comments} fullWidth multiline rows={4} id="standard-basic" label="Additional comments" variant="standard" onChange={formik.handleChange} name="comments"/>
             <ContentEnd className={"py-3"}>
                 <Button disabled={loading} type="submit" variant="contained" className="bg-dark-green">{ loading ? <><CircularProgress style={{ width: 25, height: 25 }} className="text-gray-200 mr-2 " /> Working ...</> : "Submit" }</Button>
             </ContentEnd>
            </form>

        </Content>
    </ContentRow>

</ContentColumn>
</ContentColumn>

</ContentColumn>
}