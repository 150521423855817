import Content, { ContentBetween, ContentColumn, ContentEnd, ContentRow, ContentStart, HeaderSubheader, RowCol } from "../../components/Content"
import * as React from 'react';
import { Button, Chip, Typography } from "@mui/material";
import TicketList from "./TicketList";
import { useParams } from "react-router-dom";
import CreateModal from "./CreateModal";

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ControlInput } from "./CreateDropdownItem";
import { demoApi } from "./DemoData";

export function formatCurrency(value, symbol){
    if(value === 0)
       return '0.00'
  
    let [ amount, cents ] =  value?.toString()?.split(".") || ["0","0"]
  
    let display = []
    amount.toString().split('').reverse().forEach((element, index) => {
        if(index % 3 === 0)
           display.push(',')
        display.push(element)
    });
  
    display.reverse()
    if(display[display.length-1] === ',')
    {
       display[display.length-1] = '.'
    }
    cents = cents?.length > 0 ? (cents?.length > 1 ? cents : `${cents}0`) : '00'
    return `${symbol ? symbol + ' ' :''}` + display.join("") + cents
  }

export const formatValue = (stat) => {
    if( stat.type === 'currency' ) return formatCurrency(stat.current)
    return stat.current
}

const titleCase = (value) => {
  "use strict";
   value = value ? value : this;
   console.log(this)
   return value?.slice(0,1).toUpperCase() + value?.slice(1).toLowerCase()
}

String.prototype.toTitleCase = titleCase

const objectName = "settings"

const SettingsView = () => {
    return (
        <ContentColumn className={"bg-gray-50"} style={{ width: '100vw', paddingTop: '50px' }}>
            <ContentColumn className="bg-navy-blue" style={{ paddingBottom: '100px'}}>
            <Content className="col-11 col-md-10 mx-auto py-7 hidden md:inline"  >
                <ContentBetween className={"align-items-center"}>
                   <HeaderSubheader className={"text-white"} text={objectName.toTitleCase(objectName)} subtext={`Manage ${objectName} below, create, update and view.`}/>
                   <Button size="small" className="bg-blue-600 text-white w-7rem">Update</Button>
                </ContentBetween>
            </Content>

            <Content className="col-11 col-md-10 mx-auto pt-7 md:hidden inline"  >
                <ContentColumn className={"align-items-center"}>
                   <HeaderSubheader className={"text-white"} text={objectName.toTitleCase(objectName)} subtext={"Showing metrics overview for period for Nov 14 - Nov 20"}/>
                   <CreateModal />
                </ContentColumn>
            </Content>

            </ContentColumn>

            <ContentColumn className={"px-0 col-11 col-md-10 mx-auto flex"} style={{ marginTop: '-150px', height: '80vh'}}>
                <Content className={"mb-7 bg-white border-round-md shadow-1"}>

                    <SetingsTabs />

                </Content>
            </ContentColumn>
        </ContentColumn>
    )
}

export default SettingsView;

const accountSettings = [
   { name: 'logo', label: 'Logo', type: 'image' },
   { name: 'name', label: 'Compnany name', type: 'text' },
   { name: 'business_number', label: 'Business number', type: 'phone' },
   { name: 'email', label: 'Email', type: 'email' },
   { name: 'address', label: 'Address', type: 'textarea', rows:4 },
   { name: 'currency', label: 'Currency', type: 'text' },
   { name: 'country', label: 'Country', type: 'text', flag: true },
]

const invoiceSettings = [
    { name: 'prefix', label: 'Invoice prefix', type: 'text' },
    { name: 'next_reference', label: 'Next', type: 'number' },
    { name: 'default_due_days', label: 'Due days', type: 'number' },
    { name: 'overdue_reminder', label: 'Overdue reminder days', type: 'number'},
    { name: 'default_terms', label: 'Default terms', type: 'textarea', rows:4 },
 ]

export const SettingsList = ({ row, settingsShape }) => {
  if(row)
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <nav aria-label="secondary mailbox folders">
        <List>
         { settingsShape?.map((setting , index) =>  <ListItem key={index} disablePadding>
              <InlineEditListItem setting={setting} row={row} />
          </ListItem> )}
        </List>
      </nav>
    </Box>
  );
}

export const InlineEditListItem = ({ setting, row }) => {
    const [editMode, setEditMode] = React.useState(false)
    const [value, setValue] = React.useState()

    React.useEffect(() => {
        if(row)
           setValue(row[setting?.name])
    },[row])

    const changeMode = () => {
        setEditMode(true)
    }

    const viewMode = () => {
        setValue(row[setting?.name])
        setEditMode(false)
    }

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const handleUpdate = () => {
          console.log(value)
    }

    const displayValue = () => {
        let val = row ? row[setting?.name||setting?.id] : null
        switch(setting?.type) {
            case 'image': return <img src={val} className="h-2rem"/> 
            case 'boolean': return <Chip size="small" color={value ? 'success' : 'default' } label={val ? "True":'False'} />
            default: return <Typography>{val}</Typography>
        }
    }

    return (
        editMode ? 
        <ContentColumn className={"px-3 w-full"}>
                   {
                     setting?.actual_fields?.length ?
                      <ContentRow>
                      
                      { setting.actual_fields.map((actual, idx) => {
                          let control = { ...setting, ...actual }
                          let val = row[control?.name||control?.id]
                          delete control.actual_fields

                         return  <RowCol key={idx}><ControlInput control={control} value={val} onChange={handleChange}/></RowCol> 
                          
                      }) }
                      </ContentRow>
                      :
                      <ControlInput control={setting} value={value} onChange={handleChange}/> 
                   }
                   

                   <ContentEnd className={"py-2"}>
                       <Button size="small" className="mx-1 w-5rem" onClick={viewMode}>Cancel</Button>
                       <Button size="small" className="w-5rem bg-blue-600 text-white" onClick={handleUpdate}>Save</Button>
                   </ContentEnd>
        </ContentColumn>
        :
        <ListItemButton onClick={changeMode}>
              <ListItemText primary={setting?.label} />
              { displayValue() }
        </ListItemButton>
    )
}


function SetingsTabs() {
    const [value, setValue] = React.useState('1');
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const demoSettings = demoApi['settings']
    const demoInvoiceSettings = demoApi['incoice_settings']
  
    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="General" value="1" className="capitalize font-normal"/>
              <Tab label="Services" value="2" className="capitalize font-normal"/>
            </TabList>
          </Box>
          <TabPanel value="1"><SettingsList row={demoSettings} settingsShape={accountSettings} /></TabPanel>
          <TabPanel value="2"><SettingsList row={demoInvoiceSettings} settingsShape={invoiceSettings} /></TabPanel>
        </TabContext>
      </Box>
    );
  }