import { Avatar, Box, Button, Tab } from "@mui/material";
import { useAuthContext } from "../../AuthContext";
import { Content, ContentBetween, ContentColumn, ContentStart, HeaderSubheader } from "../../components/Content";
import CreateModal from "./CreateModal";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { SettingsList } from "./SettingsView";
import { useState } from "react";
import { demoApi } from "./DemoData";

export default function MyAccount() {
    const { state } = useAuthContext();
    const { currentUser } = state;

    console.log(currentUser)

    return (
        <ContentColumn className={"bg-gray-50"} style={{ width: '100vw', paddingTop: '50px' }}>
            <ContentColumn className="bg-navy-blue" style={{ paddingBottom: '100px'}}>
            <Content className="col-11 col-md-10 mx-auto py-7 hidden md:inline"  >
                <ContentBetween className={"align-items-center"}>
                   <ContentStart className={"align-items-center"}>
                       <Avatar sx={{ width: 56, height: 56 }} src={currentUser?.gravatar_url} className="mr-3" ></Avatar>
                       <HeaderSubheader className={"text-white"} text={currentUser?.full_name} subtext={currentUser?.email} />
                   </ContentStart>
                   <Button size="small" className="bg-blue-600 text-white w-7rem">Update</Button>
                </ContentBetween>
            </Content>

            <Content className="col-11 col-md-10 mx-auto pt-7 md:hidden inline"  >
                <ContentColumn className={"align-items-center"}>
                   <HeaderSubheader className={"text-white"} text={currentUser?.full_name} subtext={currentUser?.email}/>
                   <CreateModal />
                </ContentColumn>
            </Content>

            </ContentColumn>

            <ContentColumn className={"px-0 col-11 col-md-10 mx-auto flex"} style={{ marginTop: '-150px', height: '80vh'}}>
                <Content className={"mb-7 bg-white border-round-md shadow-1"}>
                   <ProfileTabs />

                </Content>
            </ContentColumn>
        </ContentColumn>
    )
}


function ProfileTabs() {
    const [value, setValue] = useState('1');
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const accountSettings = demoApi['profile']

    const { state } = useAuthContext();
    const { currentUser } = state;

    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Profile" value="1" className="capitalize font-normal"/>
              {/* <Tab label="Activity" value="2" className="capitalize font-normal"/> */}
            </TabList>
          </Box>
          <TabPanel value="1">{ accountSettings && <SettingsList row={currentUser} settingsShape={accountSettings} />}</TabPanel>
          {/* <TabPanel value="2">Activity</TabPanel> */}
        </TabContext>
      </Box>
    );
  }