import { createCustomerForm } from "../common/FormModels";

const samuel = {
    number: 'URS008171111',
    full_name: 'Samuel Sono',
    email: 'sam@nelotec.co.za',
    role: "Admin",
    last_login: '10/03/2024T12:09:55'
}

const users = [
    samuel,
]


const service =  [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: 'Unique ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      type: 'text'
    },
    {
    id: 'recurring',
    numeric: true,
    disablePadding: false,
    label: 'Recurring',
    type: 'select',
    choices: ["Hourly", "Weekly", "Monthly", "Quarterly"]
    },
  ];


const customer =  [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: 'Unique ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      type: 'text'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      type: 'email'
    },
    {
      id: 'business_number',
      numeric: false,
      disablePadding: true,
      label: 'Business number',
      type: 'phone'
    },
{
    id: 'balance',
    numeric: true,
    disablePadding: false,
    label: 'Balance',
  },
  ];

const user = [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: 'Unique ID',
    },
    {
      id: 'full_name',
      actual_fields: [
        {id: 'first_name', label: 'Firstname'},
        {id: 'last_name', label: 'Lastname'},
    ],
      numeric: false,
      disablePadding: true,
      label: 'Name',
      type: 'text'
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      type: 'email'
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: true,
      label: 'Role',
      type: 'select',
      choices: [ "Admin", "Finance", "Technical User", "Agent"]
    },
    {
        id: 'last_login',
        numeric: false,
        disablePadding: true,
        label: 'Last login',
      },
  ];

  const profile = [
    ...user,
    {
        id: 'phone_number',
        numeric: false,
        disablePadding: true,
        label: 'Phone number',
        type: 'phone'
    },
    {
        id: 'two_factor_auth',
        numeric: false,
        disablePadding: true,
        label: 'Two-factor authentication',
        type: 'boolean'
    },
    {
        id: 'hashed_password',
        actual_fields: [
            {id: 'current_password', label: 'Current password'},
            {id: 'new_password', label: 'New password'},
            {id: 'confirm_password', label: 'Confirm password'},
        ],
        numeric: false,
        disablePadding: true,
        label: 'Change password',
        type: 'password'
    },
  ]

const incident = [
    {
      id: 'number',
      numeric: false,
      disablePadding: true,
      label: 'Unique ID',
    },
    {
      id: 'short_description',
      numeric: false,
      disablePadding: true,
      label: 'Short description',
      type: 'text'
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      type: 'select',
      choices: ['New','In progress','Resolved','Closed','Cancelled','Rejected']
    },
    {
      id: 'severity',
      numeric: false,
      disablePadding: true,
      label: 'Severity',
      type: 'select',
      choices: ['Low','Normal','Medium','High','Critical']
    },
    {
      id: 'customer__name',
      numeric: false,
      disablePadding: true,
      label: 'Customer',
      type: 'select',
      choices: [
       
      ]
    },
    {
        id: 'description',
        numeric: false,
        hidden: true,
        disablePadding: true,
        label: 'Description',
        type: 'textarea',
        rows: 4
      },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: true,
        label: 'Created at',
      },
  ];

const sampleIcident = {
    number: 'INV09876001',
    customer: {
        name: 'Binas Corner Pty Ltd',
        attention: 'John Doe',
        business_number: '(012) 110 4777',
        email: 'accounts@binas.co.za',
        address: '123 Sample Road\nExampleville\nLorem Town\n0192',
    },
    created_at: '10/03/2024T12:09:55',
    severity: 'Low',
    short_description: 'No internet',
    description: "The internet connection has been lost in the office",
    category: 'technical issue', //technical issue, product inquiry, billing question, or service request
    status: 'open', //open, in progress, on hold, resolved, or closed
    created_by: samuel,
    assigned_to: samuel,
    assigned_group: {
       name: 'Technical services',
       email: 'technical@nelotec.co.za'
    },
    escalation_history: [
        {
            action: 'Ticket was assigned',
            user: samuel,
            status: 'Open'
        },
        {
            action: 'Ticket was assigned',
            user: samuel,
            status: 'In progress'
        },
        {
            action: 'Ticket was assigned',
            user: samuel,
            status: 'Resolved'
        },
    ],
    comments: [
        {
            comments: 'This is a sample comment',
            created_by: samuel,
            created_at: '10/03/2024T12:09:55',
        },
        {
            comments: 'This is a another sample comment',
            created_by: samuel,
            created_at: '10/03/2024T13:09:55',
        },
    ],
    attachments: [

    ],
    resolve_sla: '10/03/2024T16:09:55',
  }

const incidents = [
   { ...sampleIcident },
   { ...sampleIcident, number: 'INV09876001', short_description: 'Reset password' },
   { ...sampleIcident, number: 'INV09876021', short_description: 'Faulty server' },
   { ...sampleIcident, number: 'INV09876022', short_description: 'Data depleted', status: 'In progress' },
   { ...sampleIcident, number: 'INV09876023', short_description: 'Require Quicktrav key', status: 'Resolved' },
]


const services = [
    {
        number: 'SER09876001',
        name: 'IT Support',
        recurring: 'Monthly'
    },
    {
        number: 'SER09876341',
        name: 'Server Hosting',
        recurring: 'Monthly'
    },
    {
        number: 'SER09879341',
        name: 'Software Design',
        recurring: 'Hourly'
    },
]

export const demoApi =  {
    settings : {
        name: 'Nelotec Pty Ltd',
        business_number: '(012) 110 4777',
        email: 'accounts@nelotec.co.za',
        address: '123 Sample Road\nExampleville\nLorem Town\n0192',
        currency: 'ZAR (R)',
        country: 'South Africa',
        logo: 'https://billing.nelotec.co.za/static/imgs/logo.png'
    
    },

    incoice_settings: {
        prefix : 'INV',
        next_reference: 1081781,
        default_due_days: 7,
        overdue_reminder: 7,
        default_terms: "Thank you for using our services"
    },

    quotation_settings: {
        prefix : 'QUO',
        next_reference: 2092301,
        default_due_days: 7,
        overdue_reminder: 7,
        default_terms: "Thank you for using our services"
    },
    incidents,
    profile,
    dashboard: [],
    services: services,
    customers: [],
    users: users
}

export const systemShapes = {
    incidents:  incident,
    services:  service,
    customers:  createCustomerForm,
    users:  user,
    dashboard:  incident,
} 