import './App.css';
import WebLayout from './modules/website/WebLayout';
import Layout from './modules/clientzone/Layout';
import PageNotFound from './modules/common/PageNotFound';
import Home from './modules/website/Home';
import Contact from './modules/website/Contact';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './modules/website/About';
import Services from './modules/website/Services';
import Projects from './modules/website/Projects';
import Dashboard from './modules/clientzone/Dashboard';
import GenericList from './modules/clientzone/GenericList';
import Seedmetrics from './modules/demos/Seedmetrics';
import DemoLayout from './modules/demos/DemoLayout';
import Login from './modules/clientzone/Login';
import ForgotPassword from './modules/clientzone/ForgotPassword';
import RequestAccount from './modules/clientzone/RequestAccount';
import AuthContext from './AuthContext';
import Tools from './modules/common/Tools';
import SettingsView from './modules/clientzone/SettingsView';
import MyAccount from './modules/clientzone/MyAccount';
import Logistics from './modules/demos/Logistics';

function App() {
  return (
   <AuthContext>
<BrowserRouter>
      <Routes>
        <Route path="/" element={<WebLayout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Services />} />
            <Route path="projects" element={<Projects />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<PageNotFound />} />
        </Route>

       
        <Route path="/clientzone" element={<Layout />}>
             <Route index element={<Login />} />
             <Route path="dashboard" element={<Dashboard />} />
             <Route path="forgot-password" element={<ForgotPassword />} />
             <Route path="request-account" element={<RequestAccount />} />
             <Route path="settings" element={<SettingsView />} />
             <Route path="account/profile" element={<MyAccount />} />
             <Route path=":objectName" element={<GenericList />} />
             <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="/demos" element={<DemoLayout />}>
            <Route index element={<Seedmetrics />} />
            <Route path='logistics' element={<Logistics />} />
        </Route>

      </Routes>
    </BrowserRouter>
   </AuthContext>
    
  );
}

export default App;
