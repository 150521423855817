import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import { Content, ContentBetween, ContentCenter, ContentColumn, ContentEnd, ContentRow, ContentStart, DoRedirect } from "../../components/Content";
import { CookiesProvider, useCookies } from 'react-cookie'
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import logo from '../../assets/logo.png';
import logoLight from '../../assets/logo_light.png';
import * as Yup from 'yup';
import { useState } from "react";
import axios from "axios";
import { LockOpenOutlined, LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import Slider from "react-slick";


export default function Login()
{
  const [cookies, setCookie] = useCookies(['user'])
  const [loading, setLoading] = useState(false)

  const [error, setError] = useState(null)
  const [showPass, setShowPass] = useState(false)

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required").min(8, "Username is too short."),
    password: Yup.string().required("Password is required").min(8, "Password is too short."),
  })

  function handleLogin(user) {
    setError(null)
    setLoading(true)
    axios.post("/oauth/token/", {
        username: user.username,
        password: user.password
    }, { headers: { 'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
        console.log(response)
        let _token = response.data.access_token;
        setCookie('user', response.data.access_token, { path: '/' })
        setCookie('refresh-key', response.data.refresh_token, { path: '/' })
    }).catch((e) => {
        setError(e.response?.data?.detail||"Server error")
        console.log(e)
    })
    .finally(() => setLoading(false))
    
  }

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema,
    onSubmit: (data) => {
        //console.log(data)
        handleLogin(data)
    }
  })

    return <ContentColumn>   
        
      { cookies.user ? <DoRedirect to="/clientzone/dashboard" /> : 
        <ContentRow className={"m-0 row-cols-1 row-cols-md-2"} style={{ minWidth: '100vw',  minHeight: '100vh'}}>
            <Content className={"col col-md-9 bg-blue-navy flex-1 px-8 h-full hidden md:inline login-hero"} style={{ minHeight: '100%' }}>
                    <ContentColumn className={"h-full justify-content-center"}>
                         <img src={logoLight} className="w-10rem"/>
                         <ContentStart>
                             <Typography className="font-light text-6xl text-bluegray-300 text-rightline-height-1">Client</Typography>
                             <Typography className="font-bold text-6xl text-bluegray-300 text-rightline-height-1">zone</Typography>
                         </ContentStart>
                         <Typography className="font-light text-2xl text-bluegray-300 text-rightline-height-1">Ideas, Innovation, Reality, Proven results.</Typography>
                         <Content className={"col-7 px-0"}>
                            <Typography className="font-light text-bluegray-300 text-rightline-height-1">Clientzone provides a variety of processing services to reduce waiting time for your staff and clients. We minimize the risk of unmet SLAs and lower customer support costs & risks whilst raising productivity and boosting profitability.</Typography>
                         </Content>
                         <ContentStart className={"my-3"}>
                            <Button size="small" variant="contained" className="capitalize bg-nelo-green w-10rem" href={"/"}>Nelotec Home</Button>
                         </ContentStart>
                    </ContentColumn>
            </Content>
            <Content className={"col col-md-3"} >
                <ContentEnd className={"px-2 pt-4"}>
                      <ContentColumn className={"align-items-end"}>
                         <img src={logo} className="w-10rem" style={{ marginBottom: -5 }}/>
                         <Typography className="text-sm text-bluegray-300 text-rightline-height-1">Clientzone</Typography>
                      </ContentColumn>
                </ContentEnd>
                <ContentColumn className={"justify-content-center align-items-center"} style={{ minHeight: '90%'}}>
                    <Content className={"w-11"}>
                    { error && <small className="text-center mb-5 w-12 text-red-600">Invalid username or password.</small> }
                    <Typography className="font-light text-2xl">Sign in</Typography>
                    <Typography className="font-light">Don't have an account? <Link to={"/clientzone/request-account"} className="no-underline text-blue-700">Request one</Link></Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <FormControl className="col-12">
                            <TextField name="username" error={!!formik?.errors.username} onChange={formik.handleChange}  fullWidth id="user-name" label="Username" variant="standard" />
                            {formik?.errors.username ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors.username}</small>) : null}
                        </FormControl>
                        <FormControl className="col-12">
                            <ContentStart className={"align-items-center"}>
                            <TextField
                                name="password"  
                                error={!!formik?.errors.password} 
                                onChange={formik.handleChange} 
                                fullWidth 
                                className="px-0"
                                id="user-password" 
                                label="Password" 
                                variant="standard" 
                                size="small"
                                type={showPass ? "text" : "password"} 
                                autoComplete="current-password" />

                                <IconButton
                                style={{ marginLeft: '-40px'}}
                                aria-label="toggle password visibility"
                                onClick={() => setShowPass(e => !e)}
                                onMouseDown={() => setShowPass(e => !e)}
                                >
                                {showPass ? <LockOpenOutlined /> : <LockOutlined />}
                                </IconButton>
                            </ContentStart>

                            {formik?.errors.password ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors.password}</small>) : null}
                        </FormControl>


                        <ContentBetween className={"my-3 align-items-center"}>
                           <FormControlLabel control={<Checkbox defaultChecked />} label="Remember me" />
                            <Link to={"forgot-password"}  className="no-underline text-blue-700 text-sm">Forgot password?</Link>
                        </ContentBetween>

                        <Content className={"my-3"}>
                           <Button disabled={loading} type="submit" variant="contained" className="w-10rem shadow-none bg-nelo-green text-white capitalize">{ loading ? <><CircularProgress style={{ width: 25, height: 25 }} className="text-gray-200 mr-2 " /> Working ...</> : "Sign in" }</Button>
                        </Content>

                        <Link to={"/"}  className="no-underline text-blue-700 text-sm">Nelotec Home</Link>
                    </form>
                    </Content>
                </ContentColumn>
            </Content>

        </ContentRow>
      }

    </ContentColumn>
}