import * as React from 'react';
import { useEffect } from "react"
import { IMaskInput } from "react-imask";
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

export const titleCase = (str="") => {
    return str.slice(0,1).toUpperCase() + str.slice(1)
}



export const PhoneNumberMask = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  PhoneNumberMask.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  export const CurrencyFormat = React.forwardRef(
    function NumericFormatCustom(props, ref) {
      const { onChange, ...other } = props;
  
      return (
        <NumericFormat
          {...other}
          getInputRef={ref}
          onValueChange={(values) => {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }}
          thousandSeparator
          valueIsNumericString
          prefix={'R'}
        />
      );
    },
  );
  
  CurrencyFormat.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  

const Tools = () => {
    useEffect(() => {
        String.prototype.titleCase = titleCase
    },[])
    return <></>
}

export default Tools;