import { Box, IconButton, Slide, Typography } from "@mui/material";
import Content, { ContentBetween, ContentCenter, ContentColumn, ContentEnd, ContentStart } from "../../components/Content";
import { ArrowBack, ArrowForward, ArrowRight, Code, HardwareOutlined, HiveOutlined, HorizontalRule, Html, LineAxis, PeopleAltOutlined, PermScanWifiOutlined, SatelliteAlt, WebhookOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ScrollingContent } from "../clientzone/Layout";
import ProjectList from "../../components/ProjectList";
import { useRef, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FocusDialogueFull from "../../components/FocusDialogueFull";

import softwareImg from '../../assets/laptop_code.jpeg'
import wifiImg from '../../assets/wifi_mesh.jpeg' 
import systemImg from '../../assets/integration.png'
import iotImg from '../../assets/iot_image.jpeg'
import cloud2Jpg from '../../assets/cloud_2.jpeg'


const focuses = [
    {
        icon: <Code className="text-6xl mb-4 text-nelo-light" />,
        image: softwareImg,
        title: "Software development",
        intro: [
            "In today's fast-paced digital landscape, staying ahead of the competition requires more than just off-the-shelf solutions. That's where we come in. Our team at Nelotec (Pty) Ltd specializes in crafting bespoke software solutions tailored to your unique needs, empowering you to unlock new opportunities, streamline operations, and drive growth",
            "We build software that's designed to precisely match the requirements and workflows of your business or individual users. This means it can include features and functions that are unique to the organization's processes and business objectives.",
            "We don't believe in one-size-fits-all approaches. Our team works closely with you to understand your business goals, challenges, and requirements, ensuring that every aspect of the software aligns perfectly with your vision."
        ]
    },
    {
        icon: <WebhookOutlined className="text-6xl mb-4 text-nelo-light" />,
        title: "System integration",
        image: systemImg,
        intro: [
            "One of the primary goals of system integration is to enable the exchange of data between different systems in real-time or near real-time. We allow your organization to synchronize information across multiple platforms, eliminate data silos, and ensure that data is consistent and up-to-date across the entire IT infrastructure.",
            "In today's interconnected world, the ability to seamlessly connect and synchronize your business systems is essential for maximizing efficiency, productivity, and profitability. At Nelotec, we specialize in providing comprehensive system integration services tailored to meet the unique needs of your organization.",
            "Our vendor agnostic approach enables our team to build end-to-end system integration services to address all aspects of your integration needs. Whether you're integrating legacy systems, cloud applications, IoT devices, or third-party APIs, we have you covered every step of the way."
        ]
    },
    {
        icon: <SatelliteAlt className="text-6xl mb-4 text-nelo-light" />,
        title: "Internet of Things",
        image: iotImg,
        intro: [
            "Data collected by IoT devices is processed and analyzed to extract meaningful insights, detect patterns, and make informed decisions. This may involve edge computing, where data processing occurs locally on the device itself, or cloud computing, where data is sent to remote servers for analysis.",
            "Our solutions are designed to grow with your business. We build scalable and flexible iot architectures that accommodate future expansion, changes in technology, and evolving business requirements, ensuring long-term success and adaptability.",
            "We understand the importance of delivering value for your investment. Our efficient and cost-effective approach to IOT solutions, helps you optimize resources, minimize downtime, and maximize return on investment."
        ]
    },
    {
        icon: <PermScanWifiOutlined className="text-6xl mb-4 text-nelo-light" />,
        title: "Network Solutions",
        image: wifiImg,
        intro: [
            "Cloud networking solutions enable organizations to extend their network infrastructure and services into the cloud. This includes cloud-based networking services, such as virtual private clouds (VPCs), cloud-based VPNs, and cloud-managed networking equipment, which provide scalability, agility, and cost-efficiency for cloud-based applications and services.",
            "From initial consultation to ongoing support, we offer end-to-end networking and communication services to address all aspects of your business needs. Whether you're building a small office colution, cloud applications, IoT devices networks, or buiding an ISP network, we have you covered every step of the way.",
            "Contact us today to schedule a consultation and discover how our system integration services can transform your business."
        ]
    },
    {
        icon: <PeopleAltOutlined className="text-6xl mb-4 text-nelo-light" />,
        title: "Technology Consulting",
        image: cloud2Jpg,
        intro: [
            "Internal projects in your business, often require specialized knowledge and expertise in various areas of information technology to ensure streamlined IT operations, optimized workflows, and implementation of efficient solutions whilst improving productivity and reducing downtime. ",
            "We provide strategic guidance and direction to help align your IT initiatives with your business goals and objectives. We also assess your current IT landscape, identify opportunities for innovation, and develop a roadmap to drive digital transformation and business growth."
        ]
    }
]

export default function Home()
{

const [header, setHeader] = useState(1)
const slickRef = useRef();
const webSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
  };


  const onNextSlide = () => {
    console.log(slickRef.current)
    slickRef.current.slickNext()
  }

  const onPreviousSlide = () => {
    console.log(slickRef.current)
    slickRef.current.slickPrev()
  }

  
   return  <ContentColumn style={{ maxWidth: '100vw'}}>

    <ContentColumn className={"bg-dark-green py-7 md:bg-contain-50 hero-1"}>
        <ContentColumn className={"col-10 col-md-8 mx-auto"}>
            <ContentColumn className={"flex align-items-start justify-content-center h-30rem text-6xl"}>
               <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Typography className="font-bold line-height-1 text-3xl  md:text-6xl text-white my-5">You Dream it, We build it</Typography>
               </Slide>

               <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Typography className="font-light line-height-1 text-3xl md:text-3xl text-white">We build your vision, </Typography>
               </Slide>

               <Box>
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Typography className="font-light line-height-1 text-3xl  md:text-3xl text-white">one unique solution at a time.</Typography>
                  </Slide>
               </Box>

               <Button href="/contact" className="w-10rem font-light my-4 bg-nelo-green text-xl text-gray-900 capitalize">Get started</Button>

            </ContentColumn>
        </ContentColumn>
    </ContentColumn>

   <ContentColumn>
      <ProjectList />
   </ContentColumn>


    <ContentColumn className={"bg-white py-7"} style={{ maxWidth: '100vw'}}>
        <ContentColumn className={"col-10 col-md-8 mx-auto"}>
            <Typography className="text-lg mb-5">Focus Areas</Typography>
            <ContentBetween className={"row align-items-center"}>
                <Typography className="col-md-6 text-xl line-height-2">As an organization, we provide services that introduce new ideas, technologies, and processes that enhance efficiency, effectiveness, and quality</Typography>
                <ContentEnd className={"col-md-6"}>
                    <IconButton size="large" onClick={onPreviousSlide}><ArrowBack /></IconButton>
                    <IconButton size="large" onClick={onNextSlide}><ArrowForward /></IconButton>
                </ContentEnd>
            </ContentBetween>
        </ContentColumn>

        <Content className={"col-md-12"}>

        <ContentColumn className={"pl-5 col-md-10 ml-auto"}>

             <Slider { ...webSettings } ref={slickRef}>
                { focuses.map((focus, index) => <FocusArea profile={focus} key={index}/> ) }
             </Slider>

        </ContentColumn>

        </Content>


        </ContentColumn>
        

    </ContentColumn> 

}


const FocusArea = ({ profile, selected }) => {
    return (
        <ContentColumn className={"border-round-md my-4 " + `${selected ? "bg-nelo-light":"bg-gray-pastel"}`} style={{ width: 350, minWidth: 351 }}>
            <ContentColumn className={"bg-white p-4 border-round-md border-none shadow-none"}>
                    {profile.icon}
                    <Typography className="text-lg mb-5 font-bold">{profile?.title}</Typography>
                    <Typography className="text-body line-height-2 mb-5">{profile?.intro[0].substring(0, 120)}...</Typography>
                    <ContentStart className={"align-items-center font-semibold  text-nelo-light"}>
                        Learn more 
                        <FocusDialogueFull profile={profile} />
                    </ContentStart>
            </ContentColumn>
        </ContentColumn>
    )
}
