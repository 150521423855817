import Content, { ContentBetween, ContentColumn, ContentRow, ContentStart, HeaderSubheader } from "../../components/Content"
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ArrowBackOutlined, ArrowDownwardOutlined, ArrowUpwardOutlined, CalendarMonth, DockOutlined, DocumentScanner, FilePresent, HardwareOutlined, HorizontalRuleOutlined } from "@mui/icons-material";
import { Button, Chip, Typography } from "@mui/material";
import TicketList from "./TicketList";
import moment from "moment";
import { formatValue } from "./GenericList";
import AppSnackBar from "../../components/AppSnackBar";
import CreateModal from "./CreateModal";


const fDate = "MMM DD, YYYY"
const statistics = [
    {
        measurement: "Tickets",
        descrition: 'Ticket trend',
        prefix: "",
        suffix: "",
        type: 'number',
        current: 16,
        previous: 8,
        icon: DocumentScanner
    },
    {
        measurement: "Services",
        descrition: 'Subscription growth',
        prefix: "",
        suffix: "",
        type: 'number',
        current: 3,
        previous: 3,
        icon: DockOutlined
    },
    {
        measurement: "Account balance",
        descrition: 'Accumulated in ZAR',
        prefix: "R",
        suffix: "",
        type: 'currency',
        current: 11000,
        previous: 9750,
        icon: FilePresent 
    }
]

const Dashboard = () => {
    const [dateRange, setDateRange] = React.useState({})
    return (
        <ContentColumn className={"bg-gray-50"} style={{ width: '100vw', paddingTop: '50px' }}>
            <ContentColumn className="bg-navy-blue" style={{ paddingBottom: '100px'}}>
            <Content className="col-11 col-md-10 mx-auto py-7 hidden md:inline"  >
                <ContentBetween>
                   <HeaderSubheader className={"text-white"} text={"Overview"} subtext={`Showing metrics overview for period for ${dateRange?.currentStart?.format(fDate)} - ${dateRange?.currentEnd?.format(fDate)}`}/>
                   <ReportPeriod  onChangeRange={setDateRange} />
                </ContentBetween>
            </Content>

            <Content className="col-11 col-md-10 mx-auto py-7 md:hidden inline"  >
                <ContentColumn>
                   <HeaderSubheader className={"text-white"} text={"Overview"} subtext={`Showing metrics overview for period for ${dateRange?.currentStart?.format(fDate)} - ${dateRange?.currentEnd?.format(fDate)}`}/>
                   <ReportPeriod  />
                </ContentColumn>
            </Content>

            </ContentColumn>

            <ContentColumn style={{ marginTop: '-120px'}}>
                <ContentRow className={"row px-0 col-md-10 mx-auto row-cols-3"}>
                    { statistics.map((stat, index) => <Content key={index} className={"col-12 col-md-4"}>
                       <Statistic stat={stat} index={index} />
                    </Content>)}
                </ContentRow>
            </ContentColumn>

            <ContentColumn className={"px-0 col-11 col-md-10 mx-auto"}>

            <ContentBetween className={"py-2 px-3"}>
                <HeaderSubheader className={"text-navy-blue"} text={"My tickets"} subtext={""}/>
                <CreateModal objectName={'incidents'} />
            </ContentBetween>
            
            <Content className={"mb-7 px-3"}>
               <TicketList customName="incidents" />
            </Content>

            </ContentColumn>
        </ContentColumn>
    )
}

export default Dashboard;


const ReportPeriod = ({ onChangeRange = f => f}) => {
    const [reportPeriod, setReportPeriod] = React.useState(7);
    const [dateRange, setDateRange] = React.useState({});

    const generateDates = (value) => {
        let days = value

        setReportPeriod(days);
        /*  
           When someone selects 7 days, get date 7 days ago to get trend data,
           today 7 April 2024, this week is 1 April 2024, 
           compare with week that starts 23 March 2024 ends 30 March 2024
        */
       setDateRange({
            currentStart: moment().startOf('day').subtract(days,'d'),
            currentEnd: moment().endOf('day'),
            agoStart: moment().startOf('day').subtract((days*2),'d'),
            agoEnd: moment().startOf('day').subtract((days-1),'d'),
       })
    }

    const handleChange = (event) => {
        generateDates(event.target.value)
    };

    React.useEffect(() => {
        onChangeRange(dateRange)
    },[dateRange])

    React.useEffect(() => {
        generateDates(reportPeriod)
    },[])

    return (
      <FormControl sx={{ my: 1, minWidth: 140 }} size="small" variant="standard">
      <Select
        labelId="demo-select-small-label"
        className="text-white"
        id="demo-select-small"
        value={reportPeriod}
        label="Select period"
        onChange={handleChange}
        startAdornment={<CalendarMonth className="mr-3"/>}
      >
        <MenuItem value={0}>Today</MenuItem>
        <MenuItem value={1}>From Yesterday</MenuItem>
        <MenuItem value={7}>From 7 days</MenuItem>
        <MenuItem value={30}>From 1 Month</MenuItem>
        <MenuItem value={90}>From 3 Months</MenuItem>
      </Select>
    </FormControl>
    )
}


const Statistic = ({ stat }) => {
    const BadgeStats = () => {
        let percent = ((stat.current-stat.previous)/stat.previous)*100
        
        percent = percent.toFixed(2)

        return (
            stat.current > stat.previous ?
           <Chip icon={<ArrowUpwardOutlined className="text-blue-800" />} className="bg-blue-100 text-blue-800" label={`${percent}%`}  onClick={() => {}} size="small"/>
           : stat.current == stat.previous ? <Chip icon={<HorizontalRuleOutlined className="text-gray-800" />} className="bg-gray-100 text-gray-800" label={`${percent}%`} onClick={() => {}} size="small"/> :
           <Chip icon={<ArrowDownwardOutlined className="text-red-800" />} className="bg-red-100 text-red-800" label={`${percent}%`}  onClick={() => {}} size="small"/>
        )
    }
    return (
        <ContentColumn className={"bg-white p-4 shadow-1 border-round-md"}>
            <ContentStart className={"mb-1"}>
                <stat.icon className="bg-platinum text-navy-blue p-2 border-round-md mr-2"/>
                <Typography>{stat.measurement}</Typography>
            </ContentStart>
            <ContentColumn>
                <Typography className="text-2xl">{stat.prefix}{formatValue(stat)}{stat.suffix}</Typography>
                <ContentStart className={"align-items-center"}>
                   <BadgeStats />
                   <Typography className="ml-2 text-sm text-gray">{stat.descrition}</Typography>
                </ContentStart>
            </ContentColumn>
        </ContentColumn>
    )
}