import { Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { Content, ContentBetween, ContentCenter, ContentColumn, ContentEnd, ContentRow, ContentStart, DoRedirect } from "../../components/Content";
import { CookiesProvider, useCookies } from 'react-cookie'
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import logo from '../../assets/logo.png';
import logoLight from '../../assets/logo_light.png';


export default function ForgotPassword()
{
  const [cookies, setCookie] = useCookies(['user'])

  function handleForgotPassword(user) {
    setCookie('user', user, { path: '/' })
  }

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: (data) => {
        console.log(data)
    }
  })

    return <ContentColumn>   
        
      { cookies.user ? <DoRedirect to="/clientzone/dashboard" /> : 
        <ContentRow className={"m-0 row-cols-1 row-cols-md-2"} style={{ minWidth: '100vw',  minHeight: '100vh'}}>
            <Content className={"col col-md-9 bg-blue-navy flex-1 px-8 h-full hidden md:inline"} style={{ minHeight: '100%' }}>
                    <ContentColumn className={"h-full justify-content-center"}>
                         <img src={logoLight} className="w-10rem"/>
                         <ContentStart>
                             <Typography className="font-light text-6xl text-bluegray-300 text-rightline-height-1">Client</Typography>
                             <Typography className="font-bold text-6xl text-bluegray-300 text-rightline-height-1">zone</Typography>
                         </ContentStart>
                         <Typography className="font-light text-2xl text-bluegray-300 text-rightline-height-1">Ideas, Innovation, Reality, Proven results.</Typography>
                         <Content className={"col-7 px-0"}>
                            <Typography className="font-light text-bluegray-300 text-rightline-height-1">Clientzone provides a variety of processing services to reduce waiting time for your staff and clients. We minimize the risk of unmet SLA and lower customer support risks whilst raising productivity and boosting profitability.</Typography>
                         </Content>

                    </ContentColumn>
            </Content>
            <Content className={"col col-md-3"} >
                <ContentEnd className={"px-2 pt-4"}>
                      <ContentColumn className={"align-items-end"}>
                         <img src={logo} className="w-10rem" style={{ marginBottom: -5 }}/>
                         <Typography className="text-sm text-bluegray-300 text-rightline-height-1">Clientzone</Typography>
                      </ContentColumn>
                </ContentEnd>
                <ContentColumn className={"justify-content-center align-items-center"} style={{ minHeight: '90%'}}>
                    <Content className={"w-11"}>
                    <Typography className="font-light text-2xl">Reset password</Typography>
                    <Typography className="font-light">Dont have an account? <Link to={"/clientzone/request-account"} className="no-underline text-blue-700">Request one</Link></Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField fullWidth id="user-name" label="Email address" variant="standard" />

                        <ContentEnd className={"my-4 align-items-center"}>
                            <Link to={"/clientzone"}  className="no-underline text-blue-700 text-sm">I Remember password?</Link>
                        </ContentEnd>

                        <Content className={"my-3"}>
                           <Button variant="contained" className="bg-nelo-green shadow-none">Reset password</Button>
                        </Content>
                    </form>
                    </Content>
                </ContentColumn>
            </Content>

        </ContentRow>
      }

    </ContentColumn>
}