import axios from "axios";
import { localKeyName, refreshKeyName } from "./AuthContext";
import Cookies from 'js-cookie';

const baseURL = '/api/v1';
let api = axios.create({
    baseURL: baseURL
});

let public_api = axios.create({
    baseURL: '/'
});

api.interceptors.request.use(
    async (config) => {
        let _token = Cookies.get(localKeyName);



        if(_token)
        {
            //console.log(_token)
            config.headers = {
                ...config.headers,
                Authorization : `Bearer ${_token}`,
            }
        }
        return config
    },
    (error) => Promise.reject(error)
)

api.interceptors.response.use((response) => {
    return response;
},
async (error) => {
    const originalRequest = error.config;
    // console.log("The retry outside :", originalRequest._retry)
    // console.log(error.response.status, "New outside error.")
    // console.log("outside token : ", error.config.headers['Authorization'] )

    let _token = Cookies.get(localKeyName);
    if(_token && error?.response?.status === 401 && !originalRequest._retry)
    {
        originalRequest._retry = true;
        //console.log("The retry inside :", originalRequest._retry)

        const resp = await refreshToken(public_api)

        const access_token = resp.data.access_token
        //console.log(access_token)
        addTokenToLocalStorage(access_token);
        api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        error.config.headers['Authorization'] = `Bearer ${access_token}`;

        return api(error.config);
 
    } else if ( error?.response.status === 412) {
        hardLogout() 
    }
    return Promise.reject(error)
})

export const useApi = () => {
    return api;
}


export const addTokenToLocalStorage = (access_token) => {
    Cookies.set(localKeyName, access_token)
}

export const refreshToken = async (api) => {
    let token = Cookies.get(refreshKeyName)
    //console.log(token)
    try {
        const response = api.post("/oauth/refresh-token/", { refresh_token : token });
        //Check error
        response.then(console.log).catch((error) => {
            if(error?.response?.status === 412) {
                hardLogout() 
            }
        })
        return response
    } catch (error) {
        console.log(error)        
    }
} 

export const hardLogout = () => {
    Cookies.remove(localKeyName); //Log them out if they are not authorized
    Cookies.remove(refreshKeyName); //Log them out if they are not authorized
    let location = window.location.pathname
    
    window.location = `/login?next=${location!=='/login' ? location : '/manage/dashboard'}`;
}
