import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo  from '../../assets/clientzone.png';
import Content, { ContentBetween, ContentColumn, ContentEnd, ContentRow, ContentStart, RowCol } from '../../components/Content';
import { BlenderOutlined, CellTower, Notifications, NotificationsOutlined, Person, Person2, SignalCellular0Bar, SimCard, Wifi, Wifi1BarSharp } from '@mui/icons-material';
import { Avatar, Badge, FormControl, InputLabel, Link, NativeSelect } from '@mui/material';
import { formatValue } from '../clientzone/GenericList';
import ApnList from './Tables/ApnList';
import TopSims from './Tables/TopSims';
const drawerWidth = 240;
const navItems = ['Home', 'Sim management', 'Apn management','Billing', 'Inventory', 'User management', 'Support'];

const cardList = [
    { icon: Person, measurement: "Welcome", current: "James", measureColor:"text-red-800", bgColor: 'bg-red-50' },
    { icon: CellTower, measurement: "Managed APNS", current: "3", measureColor:"text-gray-600", bgColor: 'bg-blue-50' },
    { icon: SimCard, measurement: "Total Sims", current: "185,300", measureColor:"text-gray-800", bgColor: 'bg-green-50' },
    { icon: Wifi, measurement: "Active sims", current: "179,112", measureColor:"text-blue-600", bgColor: 'bg-purple-50' }
]

function Seedmetrics(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
           <img src={logo} className='w-8rem' style={{filter: 'brightness(0)'}} />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
     
      <AppBar component="nav" className='shadow-none bg-blue-navy'>
      
        <Toolbar className='col-md-10 md:mx-auto h-2rem'>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            className='my-0 py-0'
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <img src={logo} className='w-8rem' />
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item, index) => (
              <Button key={item} className={index==0 ? 'capitalize text-gray-400 font-normal' : 'capitalize text-white font-normal'}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      

      <Box component="main" sx={{ p: 0 }} className='w-full'>
      <Toolbar />
      
      <Content className="bg-platinum w-full p-2">
            <ContentBetween className={"col-md-10 mx-auto text-blue-800 align-items-center px-4"}>
                <Typography className='text-sm'>Last login: March 26, 2024 16:43:33.</Typography>
                <ContentEnd className={"align-items-center"}>
                    <Badge badgeContent={4} className='mx-3' color="error">
                       <NotificationsOutlined />
                    </Badge>
                    <Avatar sx={{ width: 32, height: 32 }} className='mr-2 bg-gray-700 text-sm'>JW</Avatar>
                    <Content className={"hidden md:inline"}>
                    <ContentColumn >
                        <Typography>James Wolf</Typography>
                        <Typography className='text-xs line-height-1'>System administrator</Typography>
                    </ContentColumn>
                    </Content>
                    
                </ContentEnd>
            </ContentBetween>
        </Content>
        
        <ContentColumn className={"col-md-10 mx-auto mt-4 md:mt-5"}>
            <ContentRow className={"m-0 row row-cols-1 row-cols-md-4"}>
               { cardList.map((cardObj, index) => <Content key={index} className={"h-full col-md-3 mb-3"}>
                   <Statistic stat={cardObj}/>
                </Content> )}
            </ContentRow>
            
            <ContentColumn className={"px-2"}>
               <ContentBetween className={"my-3 align-items-center"}>
                   <Typography className='font-bold'>Manageg APNS</Typography>
                   <Content className={"col-6 col-md-2 "}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Sort by
                            </InputLabel>
                            <NativeSelect
                                defaultValue={30}
                                inputProps={{
                                name: 'sort_by',
                                id: 'uncontrolled-native',
                                }}
                            >
                                <option value={10}>Ten</option>
                                <option value={20}>Twenty</option>
                                <option value={30}>Name</option>
                            </NativeSelect>
                        </FormControl>
                        </Content>
               </ContentBetween>
               <ApnList />
               <Link className='mt-3 text-red-600 no-underline'>View more...</Link>
            </ContentColumn>

            <ContentColumn className={"px-2"}>
               <ContentBetween className={"my-3 align-items-center"}>
                   <Typography className='font-bold'>Top Users (5)</Typography>
                   <Content className={"col-6 col-md-2"}>

                   <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Sort by
                        </InputLabel>
                        <NativeSelect
                            defaultValue={30}
                            inputProps={{
                            name: 'sort_by',
                            id: 'uncontrolled-native',
                            }}
                        >
                            <option value={10}>Ten</option>
                            <option value={20}>Twenty</option>
                            <option value={30}>Number</option>
                        </NativeSelect>
                    </FormControl>
                   </Content>

               </ContentBetween>
               <TopSims />
               <Link className='mt-3 text-red-600 no-underline'>View more...</Link>
            </ContentColumn>


        </ContentColumn>
      </Box>
    </Box>
  );
}

Seedmetrics.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Seedmetrics;



const Statistic = ({ stat }) => {
    const  measureColor="text-blue-navy";
    const valueColor='text-gray';
   
    return (
        <ContentColumn className={(stat?.bgColor||"bg-white") + " p-3 shadow-1 border-round-sm h-full"}>
            <ContentStart className={"mb-1"}>
                <Typography className={`text-body ${stat.measureColor||measureColor}`}>{stat?.measurement}</Typography>
            </ContentStart>
            <ContentColumn>
                
                <ContentBetween className={"align-items-center"}>
                    { stat.current && <Typography className="text-xl">{stat?.prefix}{formatValue(stat)}{stat?.suffix}</Typography> }
                    { stat.descrition && <Typography className={`text-xl ${stat?.valueColor||valueColor}`}>{stat?.descrition}</Typography> }
                    <stat.icon className="text-navy-blue text-4xl"/>
                </ContentBetween>
            </ContentColumn>
        </ContentColumn>
    )
}