import Content, { ContentAround, ContentBetween, ContentCenter, ContentColumn, ContentEnd, ContentRow, ContentStart } from "../../components/Content";
import { Outlet, Link } from "react-router-dom";

import logo from '../../assets/logo.png';
import { Box, Button, Divider, Fab, FormControlLabel, IconButton, MenuItem, Switch, Typography } from "@mui/material";
import Slide from '@mui/material/Slide';
import { ArrowUpward, FacebookRounded, LinkedCamera, LinkedIn, Menu } from "@mui/icons-material";
import wapa from '../../assets/wapa-sa.svg';
import isoc from '../../assets/isoc.svg';
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import FixedBottomNavigation from "../common/BottomNav";


const Footer = () => {
    return (
        <>
        
        <Typography className="text-center mt-5 text-gray-400">Together we can grow</Typography>
        <Content className={"bg-gray-50 p-4 my-3 hidden md:inline"}>

                <ContentColumn>
                <ContentColumn className={"col-md-8 mx-auto mb-4"}>
                        
                        <ContentCenter className={"w-full"}>
                            <img src={wapa} className="mx-3"/>
                            <img src={isoc} className="mx-3"/>
                        </ContentCenter>
                </ContentColumn>

                </ContentColumn>
                 {/* Footer start */}
                        <ContentBetween className={"mx-auto col-md-8 align-items-center"}>

                            <Content className={"col-md-6"}>
                            <ContentStart>
                                <Typography className="text-sm mx-2">
                                   Copyright &copy; {new Date().getFullYear()} Nelotec (Pty) Ltd
                                </Typography>
                                <Link className="text-sm px-2 no-underline border-200 border-left-1 text-gray-700">Terms of service</Link>
                                <Link className="text-sm px-2 no-underline border-200 border-left-1 text-gray-700">Privacy</Link>
                            </ContentStart>
                            </Content>

                            <Content className={"col-md-6"}>
                                <ContentEnd >
                                    <IconButton href="https://www.facebook.com/nlstechnolgies" >
                                        <FacebookRounded className={"text-nelo-green text-3xl"}/>
                                    </IconButton>
                                    <IconButton href="https://www.linkedin.com/company/nelotecio/about">
                                        <LinkedIn className={"text-nelo-green text-3xl"}/>
                                    </IconButton>
                                </ContentEnd>
                            </Content>


                        </ContentBetween>
        </Content> 

<Content className={"bg-gray-100  p-4 my-3 inline md:hidden"}>
                <ContentColumn>
                <ContentColumn className={"col-md-8 mx-auto mb-4"}>
                        
                        <ContentCenter className={"w-full"}>
                            <img src={wapa} className="mx-3"/>
                            <img src={isoc} className="mx-3"/>
                        </ContentCenter>
                </ContentColumn>

                </ContentColumn>

                        <ContentColumn className={"mx-auto col-md-8 align-items-center"}>

                            <ContentColumn className={"mb-3"}>
                                <Typography className="text-sm mx-2">
                                   Copyright &copy; {new Date().getFullYear()} Nelotec (Pty) Ltd
                                </Typography>

                                <ContentCenter>
                                <Link className="text-sm px-2 no-underline border-200 text-gray-700">Terms of service</Link>
                                <Link className="text-sm px-2 no-underline border-200 border-left-1 text-gray-700">Privacy</Link>
                                </ContentCenter>

                            </ContentColumn>

                            <Content className={"col-md-6"}>
                                    <IconButton>
                                        <FacebookRounded className={"text-nelo-green text-3xl"}/>
                                    </IconButton>
                                    <IconButton>
                                        <LinkedIn className={"text-nelo-green text-3xl"}/>
                                    </IconButton>
                            </Content>


                        </ContentColumn>
        </Content> 

        <Content className={"py-3"}></Content>
        </>

    )
}

export default Footer;