import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Content, { ContentBetween, ContentColumn, ContentRow, ContentStart, RowCol } from './Content';
import { Button, Divider, List, ListItem } from '@mui/material';

import seedMed from '../assets/seed_medium_hd.png'
import fleetio from '../assets/fleetio.png'
import logistics from '../assets/logistics.png'


const featured = [
  {
     title: "Datametrics",
     challenge: "The client was managing data across 3 mobile network operators (MNO), each operator provided a system to allow for monitoring and management of the sim cards and their allocated data. This posed a challenge when reporting and monitoring cost centers as it meant utilization of multiple systems, multiple credentials, inconsistant billing models, and unpredicatable bill runs.",
     solution: "An application we named Datametrics. The system allowed sims from multiple networks to seemlessly authenticate on one platform, allowing the client to manage and monitor data usage. Additionally, with the solution, the system provided the client the ability to do content filtering.",
     feature_image: seedMed,
     image_height: '20rem'
  },

 {
   title: "Cloudgistics",
   challenge: "The client had a frontend UI and required a rapid solution to managege deliveries for laptops, tablets and software for their clients who are spread around the country, the application needed to capture all delivery documents, identity verification and delivery confirmation.",
   solution: "Since the client already had a frontend template for the application, The team was challenged to create a platform that addresses the client's requirements for managing the Overall logistics functions of the business, The application was built with logistics optimization and supply chain operations in mind. The various features built in were, improving efficiency, reducing costs, enhancing visibility, and providing a simple but comprehensive order management workflow.",
   feature_image: logistics,
   image_height: '16rem'
},
{
  title: "Fleetio",
  challenge: "The client owns a fleet of vehicles being utilized for various transport and rental services, management of vehicles, their location, who is driving them, etc, was always a nightmare.",
  solution: "The team built a solution based on the client's existing tracking OEM tool,we built a fleet management platform that addressed these challenges and inefficiencies faced by the client,these included vehicle location, driving statistics, Maintenance and Repairs, and providing the client with the ability to analyze data on vehicle performance, maintenance history, driver behavior, and operational metrics",
  feature_image: fleetio,
  image_height: '16rem'
}
]
export default function ProjectList() {
  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='bg-white'>
      <ContentColumn className={"col-10 col-md-8 mx-auto py-7"}>
            <Typography className="text-lg mb-5">Our projects</Typography>
            <ContentBetween className={"align-items-center"}>
                <Typography className="col-md-6 text-xl line-height-2">These are some of the projects we worked on, instrumental in helping our clients accomplish their goals and objectives.</Typography>
            </ContentBetween>
      </ContentColumn>

 <div className='bg-bluegray-50 col-md-8 mx-auto p-0 border-round-lg'>
      { featured.map((feature, index) => <WebsiteAccordian name={`panel${index}`} profile={feature} key={index} index={index+1} handleChange={handleChange} expanded={expanded}/> )}
    </div>
</div>
  );
}


const WebsiteAccordian = ({ index, profile, expanded, name, handleChange = f => f }) => {
  return (
    <Accordion expanded={expanded === name} onChange={handleChange(name)} className={ expanded === name ? 'border-round-lg bg-bluegray-50 shadow-none' : 'bg-nelo-light text-white border-none' }>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      <ContentStart className={"align-items-center col-12 col-md-11 mx-auto"}>
          <Typography className='text-2xl mr-4 font-light'>{('00'+index).slice(-2)}</Typography>
          <Typography className='text-3xl font-bold'>{profile?.title}</Typography>
      </ContentStart>
    </AccordionSummary>

    <AccordionDetails className='col-md-11 mx-auto'>
      <ContentRow className={"col-md-11 mx-auto m-0 row-cols-2"}>
      <Content className={"inline md:hidden px-0 col-12  col-md-6 flex justify-content-center align-items-start overflow-x-hidden"}>
             {/* <img src={profile?.feature_image} className='w-30rem border-round-xl ' /> */}
        </Content>

        <Content className={"px-0 col-12 col-md-6"}>
          <List>
              <ListItem>
                  <Typography className='text-lg mb-1 font-bold'>The Challenge</Typography>
              </ListItem>
              <ListItem>
                <Typography>
                    {profile?.challenge}
                </Typography>
              </ListItem>
              <ListItem>
                  <Typography className='text-lg mb-1 font-bold'>Our solution</Typography>
              </ListItem>
              <ListItem>
                <Typography>
                    {profile?.solution}
                </Typography>
              </ListItem>

          </List>
        </Content>

        <Content className={"hidden px-0 col-12 col-md-6 md:flex justify-content-center align-items-start "}>
             <Content className={`bg-bluegray-50 w-29rem h-${profile?.image_height} shadow-2 border-round-md bg-contain bg-no-repeat`} style={{backgroundImage: `url(${profile?.feature_image})`}}></Content>
        </Content>
      </ContentRow> 
     
    </AccordionDetails>
  </Accordion>
  )
}