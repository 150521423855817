import { CookiesProvider, useCookies } from 'react-cookie'
import { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import Cookies from 'js-cookie';


export const localKeyName = 'user';
export const refreshKeyName = 'refresh-key';

const Context = createContext()

const initialState = {
    currentUser: null,
    refreshKey: null,
    toast: null
}

const reducer = (state, action) => {
    switch(action.type) {
        case 'set': return {
            ...state,
            [action.item]: action.value
        }
        case 'clear': return {
            ...initialState
        }
        default: return state;
    }
}

const Provider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const logout = () => {
        return new Promise((resolve) => {
            dispatch({ type: 'clear' })
            Cookies.remove(localKeyName);
            Cookies.remove(refreshKeyName);
            resolve(null)
        })
    }

    const setToast = (toast) => {
        dispatch({ type: 'set', item: 'toast', value: toast })
    }

    const value = useMemo(() => {
       return { state, dispatch, logout, setToast }
    },[state])

    return <Context.Provider value={value}>
        {children}
    </Context.Provider>
}

export const useAuthContext = () => {
    return useContext(Context)
}

export default Provider;