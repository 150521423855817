import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { AirplaneTicket, Apartment, CreditCard, Dashboard, EmailOutlined, Home, Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(-1);
  const navigate = useNavigate()

  React.useEffect(() => {
     return () => setValue(-1)
  },[])

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={4} className='md:hidden'>
        <BottomNavigation
          showLabels
          value={value}

          onChange={(event, newValue) => {
            setValue(newValue);
            navigate(newValue)
          }}
        >
          <BottomNavigationAction value={"/about"} label="About" icon={<Apartment />} />
          <BottomNavigationAction value={"/"} label="Home" icon={<FavoriteIcon />} />
          <BottomNavigationAction value={"/contact"} label="Contact" icon={<EmailOutlined />} />
          <BottomNavigationAction value={"/clientzone"} label="Clientzone" icon={<Person />} />
        </BottomNavigation>
      </Paper>
  );
}


const menuItems = [
  {
      href: "/clientzone",
      label: "Dashboard"
  },
  {
      href: "/clientzone/incidents",
      label: "Incidents"
  },
  {
      href: "/clientzone/services",
      label: "Services"
  },
  {
      href: "/clientzone/accounts",
      label: "Acccount"
  }
]


export function ClientzoneMobileNav() {
  const [value, setValue] = React.useState(-1);
  const navigate = useNavigate()

  React.useEffect(() => {
     return () => setValue(-1)
  },[])

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={4} className='md:hidden'>
        <BottomNavigation
          showLabels
          value={value}

          onChange={(event, newValue) => {
            setValue(newValue);
            navigate(newValue)
          }}
        >
          <BottomNavigationAction value={"/clientzone/dashboard"} label="Dashboard" icon={<Dashboard />} />
          <BottomNavigationAction value={"/clientzone/incidents"} label="Incidents" icon={<AirplaneTicket />} />
          <BottomNavigationAction value={"/clientzone/services"} label="Services" icon={<EmailOutlined />} />
          <BottomNavigationAction value={"/clientzone/accounts"} label="Account" icon={<CreditCard />} />
        </BottomNavigation>
      </Paper>
  );
}


