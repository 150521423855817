import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip, Link } from '@mui/material';

function createData(name, location, usage, network, status, Bundle) {
  return { name, location, usage, network, status, Bundle };
}

const rows = [
  createData('4254015991', "Westdene Clinic", "1,900,059.01", "MTN", "Soft locked", "5 GB"),
  createData('892702002159680237',"Vembe Clinic", "2,201,000.59", "Vodacom", "Soft locked", "10 GB"),
  createData('89270200200159682795',"Westdene Clinic", "1,000.89", "Telkom", "Active", "10 GB"),
  createData('89270200200159992795', "Beneto Clinic", "9,000.89", "Telkom", "Active", "6 GB"),
  createData('89270200200159682712', "Vembe Clinic","171,000.89", "Telkom", "Overage", "5 GB"),
];

const getNetwork  = {
        MTN : <Chip label="MTN" className="bg-yellow-400" size='small'/>,
        Vodacom : <Chip label="Vodacom" className="bg-red-600 text-white"  size='small'/>,
        Telkom : <Chip label="Telkom" className="bg-cyan-600 text-white"  size='small'/>,
}

export default function TopSims() {
  return (
    <TableContainer component={Paper} className='shadow-1'>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Number (SIM)</TableCell>
            <TableCell align="right">Cost center</TableCell>
            <TableCell align="right">Usage&nbsp;(MB)</TableCell>
            <TableCell align="right">Network operator</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Bundle&nbsp;(GB)</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right"><Link className='no-underline'>{row.location}</Link></TableCell>
              <TableCell align="right">{row.usage}</TableCell>
              <TableCell align="right">{getNetwork[row.network]}</TableCell>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">{row.Bundle}</TableCell>
              <TableCell align="right"><Link className='no-underline'>Manage</Link></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}