import { Typography } from "@mui/material"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Contents = ({ className, children, ...props}) => {
   return (
    <div className={className} {...props} >
       {children}
    </div>
   )
}

export const Content = ({ className, children, ...props}) => {
   return (
    <div className={className} {...props} >
       {children}
    </div>
   )
}

export const ContentBetween = ({ className, children, ...props}) => {
    return (
     <div className={`flex justify-content-between ${className}`} {...props} >
        {children}
     </div>
    )
 }

 export const ContentStart = ({ className, children, ...props}) => {
    return (
     <div className={`flex justify-content-start ${className}`} {...props} >
        {children}
     </div>
    )
}

export const ContentCenter = ({ className, children, ...props}) => {
   return (
    <div className={`flex justify-content-center ${className}`} {...props} >
       {children}
    </div>
   )
}

 export const ContentEnd = ({ className, children, ...props}) => {
    return (
     <div className={`flex justify-content-end ${className}`} {...props} >
        {children}
     </div>
    )
}

 export const ContentAround = ({ className, children, ...props}) => {
    return (
     <div className={`flex justify-content-around ${className}`} {...props} >
        {children}
     </div>
    )
}

 export const ContentColumn = ({ className, children, ...props}) => {
    return (
     <div className={`flex flex-column ${className}`} {...props} >
        {children}
     </div>
    )
}

 export const ContentRow = ({ className, children, ...props}) => {
    return (
     <div className={`row ${className}`} {...props} >
        {children}
     </div>
    )
}

export const RowCol = ({ className, children, ...props}) => {
   return (
    <div className={`col ${className}`} {...props} >
       {children}
    </div>
   )
}

export const HeaderSubheader = ({ text, subtext, className, headClasses="text-2xl font-semibold", subClasses="text-sm", ...props}) => {
   return (
    <ContentColumn className={className} { ...props }>
        <Typography className={headClasses}>{text}</Typography>
        <Typography className={subClasses + ' sm:col-11 md:col-12 p-0'}>{subtext}</Typography>
    </ContentColumn>
   )
}

export const DoRedirect = ({ to }) => {
   const navigate = useNavigate();

    useEffect(() => {
       navigate(to)
    },[])
    return null
}

export default Contents;
