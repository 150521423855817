import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthContext } from '../AuthContext';

export default function AppSnackBar({ onCancel = f => f }) {
  const [toastState, setToastState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = toastState;

  const { state, setToast } = useAuthContext()
  const { toast } = state

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setToastState({ ...toastState, open: false });
    setToast(null)
  };

  React.useEffect(() => {
     if(toast?.message)
     {
        setToastState({ ...toastState, open: true });
     } else {
        setToastState({ ...toastState, open: false });
     }
  },[toast?.message])

  const action = (
    <React.Fragment>
      { toast?.undo && <Button color="secondary" size="small" onClick={handleClose} className='text-nelo-green'>
        UNDO
      </Button> }
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={toast?.message}
        key={vertical + horizontal}
        action={action}
      />
    </Box>
  );
}