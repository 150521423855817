import { IconButton, Typography } from "@mui/material";
import Content, { ContentBetween, ContentColumn, ContentRow, ContentStart, RowCol } from "../../components/Content";
import { HiveOutlined, HorizontalRule, RemoveRedEye } from "@mui/icons-material";

const vission  = "Our vision is to be a global leader in technology innovation, recognized for our unwavering commitment to pushing the boundaries of what's possible. We aspire to inspire a future where technology seamlessly integrates with everyday life, empowering individuals and organizations to achieve their fullest potential. Through continuous innovation, collaboration, and a relentless pursuit of excellence, we aim to shape the future of technology and make a meaningful impact on society, driving progress and prosperity for generations to come."
const mission = "To pioneer innovative solutions that revolutionize industries, empower businesses, and enhance lives through the transformative power of technology. We are committed to delivering exceptional value to our clients by relentlessly pursuing excellence, fostering creativity, and driving positive change within the republic."
export default function About()
{
    return <ContentColumn className="py-3 bg-white" style={{ width: '100vw'}}>

        <ContentColumn className={"py-0"}>
            <ContentColumn className={"col-md-8 mx-auto"}>
                <ContentColumn className={"flex col-md-10 align-items-start justify-content-center h-15rem mt-5"}>
                <ContentStart className={"align-items-center"}><Typography className="text-3xl line-height-1">We convert your ideas</Typography><HorizontalRule className="align-self-center text-6xl" fontSize="34px"/></ContentStart>
                <Typography className="text-2xl text-gray-500 line-height-1">Into reality.</Typography>
                </ContentColumn>
            </ContentColumn>
        </ContentColumn>

    <ContentColumn className={"py-3 bg-nelo-light"}>
    <ContentColumn className={"col-md-8 mx-auto py-3 px-4"}>
      <Typography className="text-lg mb-5">Who we are</Typography>

      <ContentRow className={"m-0 row-cols-1 row-cols-md-2"}>
            <RowCol className={"md:px-0 col-md-6"}>
                    <ContentBetween className={"align-items-center"}>
                        <Typography className="col-md-8 text-xl line-height-2 text-gray-800">Nelotec (Pty) Ltd<br/>Established in 2015</Typography>
                    </ContentBetween>
            </RowCol>
            <RowCol className={"md:px-0 col-md-5"}>
                <Typography className="text-body line-height-2 text-gray-800">
                Nelotec is a dynamic and innovative software development and technology solutions company committed to delivering cutting-edge solutions that empower businesses and organizations to thrive in the ever-evolving digital landscape. With a team of skilled professionals, we specialize in crafting bespoke technology solutions, software applications and systems, leveraging the latest technologies to address unique challenges and drive measurable results.
                </Typography>
            </RowCol>
        
        </ContentRow>
    </ContentColumn>
    </ContentColumn>

    <ContentColumn className={"col-md-8 mx-auto md:py-3"}>

        <ContentRow className={"row-cols-1 row-cols-md-2"}>
            <Content className={"col col-md-6"}>
               <FocusArea profile={{ title: "Our mission", content: mission}} />
            </Content>
            <Content className={"col col-md-6"}>
               <FocusArea profile={{ title: "Our vission", content: vission}} />
            </Content>
        </ContentRow>
        

        </ContentColumn>


    </ContentColumn>
}


const FocusArea = ({ profile, selected }) => {
    return (
        <ContentColumn className={"w-full mr-2 border-round-md md:my-5 " + `${selected ? "bg-lime-nelo":"bg-gray-200"}`} >
        <ContentColumn className={"h-full bg-white px-5 md:px-7 py-5 shadow-2 border-round-md"}>
               <Typography className="text-lg mb-5 font-bold">{profile?.title}</Typography>
               <Typography className="text-body line-height-2 text-gray-800">{profile?.content}</Typography>
        </ContentColumn>
        </ContentColumn>
    )
}