import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, FormControl, IconButton, Input, InputLabel, ListItemIcon, MenuItem, Select } from '@mui/material';
import { AddBusiness, LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { getCreateModel } from '../common/FormModels';
import { CurrencyFormat, PhoneNumberMask, titleCase } from '../common/Tools';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AppSnackBar from '../../components/AppSnackBar';
import { useAuthContext } from '../../AuthContext';
import { ContentStart } from '../../components/Content';

export default function CreateDropdownItem({ onModelClose = f => f, onComplete = f => f, modelName }) {
    
    const formShape = React.useMemo(() => {
        return modelName ? getCreateModel(modelName) : [] //Always return array because we are not checking at map if you are not null
      },[modelName])

    const initialValues = React.useMemo(() => {
        return {}
    },[modelName])

    const validationSchema = React.useMemo(() => {
       const obj = {} 
       formShape.forEach((control) => { 
            let field = Yup.string()
            field = control.type === 'email' ? field.email('Email is invalid') : field
            field = control.required ? field.required(`${control.label} is required`) : field 
            field = control.min ? field.min(control.min, `Too short, minimum ${control.min} digits required`) : field 
            field = control.max ? field.max(control.max, `${control.label} too long, max ${control.max}`) : field 
            obj[control.name||control.id] = field
            
       })

       return Yup.object().shape(obj)
    },[modelName])

    return <>
    <ActualDialogue 
        onModelClose={onModelClose} 
        modelName={modelName} 
        validationSchema={validationSchema} 
        initialValues={initialValues} />
    </>
}

const ActualDialogue = ({ onModelClose = f => f, onComplete = f => f, modelName, initialValues, validationSchema }) => {
    const [open, setOpen] = React.useState(true);

    const { state, setToast } = useAuthContext()
    const { toast } = state

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: (data) => {
          console.log(data)
          setToast({ message: "The form was submitted successfully.", undo: false })
      }
    })
  
    const handleClose = () => {
      setOpen(false);
      const timeout = setTimeout(() => { //Important to preserve the closing animation if any
          onModelClose() //Before removing elemy from DOM
          clearTimeout(timeout)
      },500)
  
    };
  
    const formShape = React.useMemo(() => {
      return modelName ? getCreateModel(modelName) : [] //Always return array because we are not checking at map if you are not null
    },[modelName])
  
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: formik.handleSubmit,
            noValidate: true
          }}
        >
  
          <DialogTitle>Create {modelName.slice(0,-1)}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create an {modelName.slice(0,-1)}, please fill in the form below. this {modelName.slice(0,-1)} will be automatically assigned to your account.
            </DialogContentText>

            <FormControlInputs formik={formik} formShape={formShape} />
  
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
  
        </Dialog>
      </React.Fragment>
    );
}

export const FormControlInputs = ({ formik, formShape }) => {
    const onValidateUnique = (e) => {
        console.log(e)
    }

    return <>
    {formShape.map((control, index) => <React.Fragment key={index}>
         <ControlInput 
             key={index} 
             value={formik[control.name||control.id]}
             errors={formik?.errors}
             control={control} 
             onSelect={(e) => formik.setFieldValue(control.name||control.id, e.target.value)}
             onChange={(e) => { formik.handleChange(e); onValidateUnique(e) }} />
    </React.Fragment>)}
  
    </>
}


export const ControlInput = ({ onChange, onSelect, value, errors={}, control, options }) => {
    const controlName = control.name||control.id
    return <>

    { ['autocomplete'].includes(control.type) && <Autocomplete
        options={options}
        getOptionLabe={(option) => option.name||option.id}
        id="auto-complete"
        autoComplete
        includeInputInList
        renderInput={(params) => (
          <TextField {...params} label="autoComplete" variant="standard" />
        )}
      />
      }

    { ['text','email','number','image'].includes(control.type) && <FormControl variant="standard" fullWidth>
              <TextField
                  autoFocus={control?.focus}
                  required={control.required}
                  margin="dense"
                  value={value}
                  error={!!errors[control?.name||control.id]}
                  onChange={onChange}
                  id={controlName||control.id}
                  name={controlName||control.id}
                  label={titleCase(control.label||controlName||control.id)}
                  type={control.type === 'image' ? 'url' : control.type}
                  fullWidth
                  variant="standard"
              />  
  
            </FormControl>
            }

            {
                ['password',].includes(control.type) && <FormControl variant="standard" fullWidth>

                <PasswordControl 
                    autoFocus={control?.focus}
                    required={control.required}
                    value={value}
                    error={!!errors[control?.name||control.id]}
                    onChange={onChange}
                    id={controlName||control.id}
                    name={controlName||control.id}
                    label={titleCase(control.label||controlName||control.id)}
                    fullWidth
                    variant="standard"
                />
                </FormControl>

            }

  { ['currency'].includes(control.type) && <FormControl variant="standard" style={{ width: '100%'}}>
  <TextField
          label={titleCase(control.label||controlName)}
          value={value}
          onChange={onChange}
          required={control.required}
          id={controlName}
          name={controlName}
          error={!!errors[control?.name]}
          symbol="R"
          InputProps={{
            inputComponent: CurrencyFormat
          }}
          variant="standard"
        />
  </FormControl> }
  
  
  { ['phone'].includes(control.type) && <FormControl variant="standard" style={{ width: '100%'}}>
      <InputLabel required={control.required} htmlFor={controlName} className={`${!!errors[control?.name] && 'text-red-600'}`}>{titleCase(control.label||controlName)}</InputLabel>
      <Input
      value={value}
      onChange={onChange}
      error={!!errors[control?.name]}
      autoFocus={control?.focus}
      required={control.required}
      id={controlName}
      name={controlName}
      inputComponent={PhoneNumberMask}
      variant="standard"
      />
  </FormControl> }
  
  { ['select'].includes(control.type) &&  <FormControl variant="standard" fullWidth>
          <InputLabel required={control.required} id="demo-simple-select-standard-label">{titleCase(control.label||controlName)}</InputLabel>
          <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          error={!!errors[control?.name]}
          value={value}
          onChange={onSelect}
          label="Severity"
          fullWidth
          >
          <MenuItem value={undefined}>
              <em>Select {controlName}</em>
          </MenuItem>
          { control.choices?.map((choice, idx) => <MenuItem key={idx} value={choice}>{choice}</MenuItem>)}
          </Select>
  </FormControl> }
  
  { ['textarea'].includes(control.type) && <TextField
          autoFocus
          required={control?.required}
          value={value}
          error={!!errors[control?.name]}
          onChange={onChange}
          multiline
          rows={control.rows||2}
          margin="dense"
          id={controlName}
          name={controlName}
          label={titleCase(control.label||controlName)}
          type={controlName}
          fullWidth
          variant="standard"
  /> }

  {errors[controlName] ? (<small className="text-xs py-0 w-full text-red-600">{errors[controlName]}</small>) : null}
    </>
}


const PasswordControl = (props) => {
    const [showPass, setShowPass] = React.useState(false)
    return (
        <ContentStart className={"align-items-center"}>
                <TextField
                    name={props.name} 
                    error={props.error} 
                    onChange={props.onChange} 
                    fullWidth={props.fullWidth}
                    className="px-0"
                    id={props.id}
                    label={props.label}
                    variant={props.variant} 
                    size="small"
                    type={showPass ? "text" : "password"} 
                    autoComplete="current-password" />
                    <IconButton
                    style={{ marginLeft: '-40px'}}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(e => !e)}
                    onMouseDown={() => setShowPass(e => !e)}
                    >
                    {showPass ? <LockOpenOutlined /> : <LockOutlined />}
                    </IconButton>
                </ContentStart>
    )
}