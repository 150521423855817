
import Content, { ContentAround, ContentBetween, ContentCenter, ContentColumn, ContentEnd, ContentRow, ContentStart, RowCol } from "../../components/Content";
import { Outlet, Link, useNavigation, useLocation } from "react-router-dom";

import logo from '../../assets/logo_light.png';
import { Box, Button, ButtonGroup, Divider, FormControlLabel, IconButton, MenuItem, Switch, Typography } from "@mui/material";
import Slide from '@mui/material/Slide';
import { ArrowOutwardSharp, FacebookRounded, LinkedCamera, Menu } from "@mui/icons-material";
import wapa from '../../assets/wapa-sa.svg';
import isoc from '../../assets/isoc.svg';
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import FixedBottomNavigation from "../common/BottomNav";
import Footer from "../common/Footer";

import clientzoneImg from '../../assets/favicon.ico';

const services = ["Software solutions","Network solutions","IOT Solutions","Consulting Services","Your technology partner"]
const navLinks = [
    {
        href: '/',
        label: 'Home'
    },
    {
        href: '/about',
        label: 'About'
    },
    {
        href: '/contact',
        label: 'Contact'
    },
    {
        href: '/clientzone/',
        label: 'Clientzone',
        image: clientzoneImg
    },
]

export default function Layout()
{
    const [serviceIdex, setServiceIndex] = useState(0)
    const [bgcolor, setBgColor] = useState("bg-dark-green")

    const navigation = useLocation()

    const isNavactive = (link) => navigation.pathname === link

    const onScreenHeight = (height) => {
        if(height > 480 && height < 627) {
            setBgColor("bg-dark-green")
        } else if (height > 627) {
            setBgColor("bg-dark-green")
        }
        else {
            setBgColor("bg-dark-green")
        }
    }

    useEffect(() => {
        document.title = "Nelotec (Pty) Ltd"
    },[])

    useEffect(() => {
       const inter = setInterval(() => {
           let prev = serviceIdex + 1
           if(prev == services.length)
               prev = 0

           setServiceIndex(prev)
        },[7000])

        return () => clearInterval(inter)

    },[serviceIdex])

    return <ContentColumn className="mx-auto relative" style={{ height: '100vh', maxWidth: '100vw', overFlow: 'hidden' }}>
   <ContentColumn className={`${bgcolor} absolute top-0 left-0 py-0 font-bold z-5`} style={{ width: '100vw'}}>
            <Content className={"mx-auto col-10 col-md-8"}>

                <ContentBetween className={"py-1 align-items-center"}>
                      <Content className={"inline md:hidden flex-1"}>
                        <ContentColumn className={"align-items-center py-3"}>
                                 <Link to="/">
                                <img src={logo} className="w-12rem" />
                                </Link>
                                { services.map((service, index) => 
                               serviceIdex===index && <Slide in={serviceIdex===index} direction="right" key={index}>
                                    <Typography className="text-sm text-right text-platinum line-height-1">{service}</Typography>
                                </Slide> 
                                )}
                        </ContentColumn>
                        </Content>

                        <Content className={"hidden md:inline"}>
                            <ContentColumn className={""}>
                                    <Link to="/">
                                        <img src={logo} className="w-12rem" />
                                    </Link>
                                    { services.map((service, index) => 
                                serviceIdex===index && <Slide in={serviceIdex===index} direction="right" key={index}>
                                        <Typography className="text-sm text-right text-platinum  line-height-1">{service}</Typography>
                                    </Slide> 
                                    )}
                            </ContentColumn>
                        </Content>


                        <Content className={"hidden md:inline"}>
                        <ContentStart className={"align-items-center"}>
                            { navLinks.map((link, index) => <ContentStart className={"align-items-center"} key={index}><Button {...link} size="sm" variant="text" className={ isNavactive(link.href) ?"capitalize mx-3 text-nelo-light" : "capitalize mx-3 text-platinum"}>{link?.image && <img src={link?.image} className="w-1rem mr-2"/> }{link.label}</Button></ContentStart>)}
                        </ContentStart>
                        </Content>

                </ContentBetween>
            </Content>

        </ContentColumn>


<ScrollingContent className="bg-gray-50  overflow-y-scroll relative pb-7" style={{ height: '110vh' }} setContent={onScreenHeight}>
    <ContentColumn className={"absolute oveflow-y-scroll"} style={{ height: '110vh'}}>
        <Outlet />
        <Footer />
    </ContentColumn>
</ScrollingContent>

     <FixedBottomNavigation />
</ContentColumn>
    
    
}

const CookieTerms = () => {
    const [notAccepted, setNotAccepted] = useState(false)
    const [cookie, setCookie] = useState(null)

    useEffect(() => {
        const cookies = localStorage.getItem("accept_ohquoh9xooyeV1shaek0_cookies")
        if(!cookies)
        {
            setNotAccepted(true)
        } else {
            setNotAccepted(false)
        }
    },[cookie])

    const setCookies = () => {
        localStorage.setItem("accept_ohquoh9xooyeV1shaek0_cookies","true")
        setCookie("true")
    }


    return   <Slide direction="up" in={notAccepted} mountOnEnter unmountOnExit>
        <Box className={"absolute bottom-0 left-0 bg-blue-midnight text-white p-7"}>
        <ContentRow className={"mx-auto col-md-8 align-items-center"}>
            <Content className={"col-md-6 mb-3"}>
               <Typography className="">This website uses cookies by using our website, you agree to our use of cookies.</Typography>
            </Content>
            <Content className={"col-md-6 mb-3"}>
                <Button color="success" variant="contained" onClick={() => setCookies()}>Accept & Close</Button>
            </Content>
        </ContentRow>
        </Box>
    </Slide>

}


export const ScrollingContent = ({children, className, style, setContent = f => f}) => {
    const scroller = useRef();

    const onScroll = () => {
        if(scroller.current) {
            const position = window.scrollY;
            const { scrollTop, scrollLeft } = scroller.current
            //console.log(scrollTop)
            setContent(scrollTop)
        }
    }

    return <div className={className} ref={scroller} style={style} onScroll={onScroll}>
        {children}
    </div>
}