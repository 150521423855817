import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FormControlInputs } from './CreateDropdownItem';
import { useFormik } from 'formik';
import { systemShapes } from './DemoData';

export default function CreateModal({ objectName }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: (data) => {
      console.log(data)
    }
  })

  const formShape = React.useMemo(() => {
     return systemShapes[objectName]
  },[objectName])

  return (
    <React.Fragment>
      <Button variant="contained" className="shadow-none capitalize" onClick={handleClickOpen}>
        Create new
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: formik.handleSubmit
        }}
      >
        <DialogTitle>Create {objectName?.slice(0,-1)}</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          <DialogContentText className='mb-4'>
            To create an {objectName?.slice(0,-1)}, please fill in the form below. be consise.

          </DialogContentText>
             <FormControlInputs formik={formik} formShape={formShape} />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create {objectName?.slice(0,-1)}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}