import Content, { ContentBetween, ContentColumn, ContentRow, ContentStart, HeaderSubheader } from "../../components/Content"
import * as React from 'react';
import { Button, Chip, Typography } from "@mui/material";
import TicketList from "./TicketList";
import { useParams } from "react-router-dom";
import CreateModal from "./CreateModal";


export function formatCurrency(value, symbol){
    if(value === 0)
       return '0.00'
  
    let [ amount, cents ] =  value?.toString()?.split(".") || ["0","0"]
  
    let display = []
    amount.toString().split('').reverse().forEach((element, index) => {
        if(index % 3 === 0)
           display.push(',')
        display.push(element)
    });
  
    display.reverse()
    if(display[display.length-1] === ',')
    {
       display[display.length-1] = '.'
    }
    cents = cents?.length > 0 ? (cents?.length > 1 ? cents : `${cents}0`) : '00'
    return `${symbol ? symbol + ' ' :''}` + display.join("") + cents
  }

export const formatValue = (stat) => {
    if( stat.type === 'currency' ) return formatCurrency(stat.current)
    return stat.current
}

const titleCase = (value) => {
  "use strict";
   value = value ? value : this;
   console.log(this)
   return value?.slice(0,1).toUpperCase() + value?.slice(1).toLowerCase()
}

String.prototype.toTitleCase = titleCase

const GenericList = () => {
    const { objectName } = useParams();
    return (
        <ContentColumn className={"bg-gray-50"} style={{ width: '100vw', paddingTop: '50px' }}>
            <ContentColumn className="bg-navy-blue" style={{ paddingBottom: '100px'}}>
            <Content className="col-11 col-md-10 mx-auto py-7 hidden md:inline"  >
                <ContentBetween className={"align-items-center"}>
                   <HeaderSubheader className={"text-white"} text={objectName.toTitleCase(objectName)} subtext={`Manage ${objectName} below, create, update and view.`}/>
                   <CreateModal objectName={objectName} />
                </ContentBetween>
            </Content>

            <Content className="col-11 col-md-10 mx-auto pt-7 md:hidden inline"  >
                <ContentColumn className={"align-items-center"}>
                   <HeaderSubheader className={"text-white"} text={objectName.toTitleCase(objectName)} subtext={"Showing metrics overview for period for Nov 14 - Nov 20"}/>
                   <CreateModal />
                </ContentColumn>
            </Content>

            </ContentColumn>

            <ContentColumn className={"px-0 col-11 col-md-10 mx-auto flex"} style={{ marginTop: '-150px', height: '80vh'}}>
                <Content className={"mb-7"}>
                   <TicketList />
                </Content>
            </ContentColumn>
        </ContentColumn>
    )
}

export default GenericList;