import Content, { ContentAround, ContentBetween, ContentCenter, ContentColumn, ContentEnd, ContentRow, ContentStart, DoRedirect, RowCol } from "../../components/Content";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";

import logo from '../../assets/logo_light.png';
import { Avatar, Badge, Box, Button, ButtonGroup, Divider, FormControlLabel, IconButton, MenuItem, Switch, Typography } from "@mui/material";
import Slide from '@mui/material/Slide';
import { ArrowOutwardSharp, FacebookRounded, LinkedCamera, Menu, Notifications, NotificationsOutlined } from "@mui/icons-material";
import wapa from '../../assets/wapa-sa.svg';
import isoc from '../../assets/isoc.svg';
import { forwardRef, useCallback, useEffect, useRef, useState, useTransition } from "react";
import FixedBottomNavigation, { ClientzoneMobileNav } from "../common/BottomNav";
import Footer from "../common/Footer";
import '../../Clientzone.css';
import AccountMenu from "../../components/AccountMenu";
import { CookiesProvider, useCookies } from 'react-cookie'
import { useAuthContext } from "../../AuthContext";
import AppSnackBar from "../../components/AppSnackBar";

const services = ["Software solutions","Network solutions","IOT Solutions","Consulting Services","Your technology partner"]

const menuItems = [
    {
        href: "/clientzone",
        label: "Dashboard",
        roles: null
    },
    {
        href: "/clientzone/incidents",
        label: "My Tickets",
        roles: null
    },
    {
        href: "/clientzone/services",
        label: "My Services",
        roles: null
    },
    {
        href: "/clientzone/customers",
        label: "Customers",
        roles: ['is_account_admin','is_finance']
    },
    {
        href: "/clientzone/users",
        label: "Users",
        roles: ['is_account_admin','is_finance']
    }
]


const unAuth = ['/clientzone','/clientzone/forgot-password','/clientzone/request-account']

export default function Layout()
{
    const [serviceIdex, setServiceIndex] = useState(0)
    const [bgcolor, setBgColor] = useState("bg-navy-blue")

    const [cookies, setCookie] = useCookies(['user'])
    const location = useLocation();
    const navigate = useNavigate();

    const { state } = useAuthContext();
    const { currentUser } = state

    const onScreenHeight = (height) => {
        if(height > 480 && height < 627) {
            setBgColor("bg-navy-blue")
        } else if (height > 627) {
            setBgColor("bg-navy-blue")
        }
        else {
            setBgColor("bg-navy-blue")
        }
    }

    useEffect(() => {
        document.title = "Nelotec - Clientzone"
    },[])

    useEffect(() => {
       const inter = setInterval(() => {
           let prev = serviceIdex + 1
           if(prev == services.length)
               prev = 0

           setServiceIndex(prev)
        },[7000])

        return () => clearInterval(inter)

    },[serviceIdex])

    const hasRole = (option) => {
        let role = false
        if(!!option.roles) {
            role = currentUser?.is_superuser || currentUser?.is_admin
            option.roles.forEach((role) => {
                role = role || currentUser[role]
            })
            return role
        } 
        return !role
    }




    if(unAuth.includes(location.pathname)  && cookies?.user )
    {
        return <DoRedirect to="/clientzone/dashboard" />
    } else if(!cookies?.user && !unAuth.includes(location.pathname)) {
         return <DoRedirect to="/clientzone" /> 
    }


    return <ContentColumn className="bg-gray-50 mx-auto relative" style={{ height: '100vh', maxWidth: '100vw', overFlow: 'hidden' }}>
            <AppSnackBar />


 { cookies?.user ?  <ContentColumn className={`${bgcolor} absolute top-0 left-0 py-0 font-bold z-5`} style={{ width: '100vw'}}>

            <Content className={"mx-auto col-11 col-md-10"}>

                <ContentBetween className={"py-1 align-items-center"}>

                        <Content className={"inline md:hidden"}>
                            <ContentColumn className={"align-items-center py-3"}>
                                    <Link to="/" style={{ marginBottom: -7 }}>
                                    <img src={logo} className="w-10rem"/>
                                    </Link>
                                    <Typography className="text-sm text-right text-platinum line-height-1">Clientzone</Typography>
                            </ContentColumn>
                        </Content>

                        <Content className={"hidden md:inline"}>
                            <ContentColumn className={""}>
                                    <Link to="/" style={{ marginBottom: -7 }}>
                                        <img src={logo} className="w-12rem"  />
                                    </Link>
                                    <Typography className="text-sm text-right text-platinum line-height-1">Clientzone</Typography>
                            </ContentColumn>
                        </Content>


                        <Content className={"hidden md:inline"}>
                        <ContentStart className={"align-items-center"}>
                            { menuItems.map((menuItem, index) => hasRole(menuItem) && <Button href={menuItem.href} key={index} size="sm" variant="text" className="capitalize mx-3 text-platinum">{menuItem.label}</Button> )}
                        </ContentStart>
                        </Content>


                        <Content>
                            <ContentStart className={"align-items-center"}>
                                <AccountMenu />
                            </ContentStart>
                        </Content>

                </ContentBetween>
            </Content>

    </ContentColumn> : null }


<ScrollingContent className="overflow-y-scroll relative pb-7" style={{ height: '100vh' }} setContent={onScreenHeight}>
    <ContentColumn className={"absolute oveflow-y-scroll"} style={{ height: '100vh'}}>
        <Outlet />
    </ContentColumn>
</ScrollingContent>

{ cookies?.user ? <ClientzoneMobileNav /> : null }
</ContentColumn>
    
    
}


export const ScrollingContent = ({children, className, style, setContent = f => f}) => {
    const scroller = useRef();

    const onScroll = () => {
        if(scroller.current) {
            const position = window.scrollY;
            const { scrollTop, scrollLeft } = scroller.current
            //console.log(scrollTop)
            setContent(scrollTop)
        }
    }

    return <div className={className} ref={scroller} style={style} onScroll={onScroll}>
        {children}
    </div>
}
